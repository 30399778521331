import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function JobOffers({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={852.182}
      height={617.73}
      viewBox="0 0 852.182 617.73"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M688.013 555.912h104v60h-104z"
      />
      <path
        d="M578.453 602.03c-.934 3.176-1.367 6.879.572 9.561a9.656 9.656 0 004.086 2.993 29.112 29.112 0 0012.985 1.906 6.132 6.132 0 004.593-1.764 56.586 56.586 0 0019.575-33.393 3.166 3.166 0 00-.104-2.048 3.247 3.247 0 00-1.363-1.157 30.653 30.653 0 00-7.31-3.338c-14.08-3.635-29.546 15.372-33.034 27.24zM726.095 557.533a20.732 20.732 0 006.844 5.212 9.608 9.608 0 008.364-.142c3.873-2.205 4.97-7.329 4.66-11.775-.57-8.223-4.499-15.633-9.013-22.35-1.428-2.126-4.59-8.451-7.365-8.53-1.318-.037-6.247 4.742-7.587 5.652-4.893 3.323-8.283 5.7-7.265 12.08 1.136 7.115 6.574 14.73 11.362 19.853z"
        fill="#2f2e41"
      />
      <path
        d="M683.19 217.423c-20.833-1.616-40.732-1.017-61.603-2.088-1.753-.09-.908 19.986-2.725 30.888-.934 5.603-32.348 333.837-28.45 334.502a259.062 259.062 0 0030.331 4.492c4.134.364 42.663-213.506 40.817-230.887 5.045 7.399 22.812 41.56 31.797 80.854 11.854 51.846 15.343 110.489 22.711 107.2a44.138 44.138 0 0118.611-14.72c12.178-4.53-7.558-253.924-28.41-265.39-.256-7.299-.524-14.778-3.275-21.544-3.88-9.543-11.535-17.163-19.805-23.307z"
        fill="#2f2e41"
      />
      <path
        fill="#3f3d56"
        d="M851.787 617.73H277.706v-2.182h574.476l-.395 2.182z"
      />
      <path
        fill="#e6e6e6"
        d="M0 294.368h471.013v92.995H0zM0 147.426h471.013v92.995H0zM0 0h471.013v92.995H0z"
      />
      <path
        d="M10.041 82.953h450.931V10.041H10.042zM10.041 230.704h450.931v-72.912H10.042zM10.041 377.322h450.931V304.41H10.041z"
        fill="#fff"
      />
      <circle
        cx={49.517}
        cy={45.894}
        r={27.778}
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M115.942 35.024h274.154v6.039H115.942zM115.942 50.724h274.154v6.039H115.942z"
      />
      <circle cx={49.517} cy={194.527} r={27.778} fill="#ccc" />
      <path
        fill="#ccc"
        d="M115.942 183.658h274.154v6.039H115.942zM115.942 199.358h274.154v6.039H115.942z"
      />
      <circle cx={49.517} cy={341.47} r={27.778} fill="#ccc" />
      <path
        fill="#ccc"
        d="M115.942 330.6h274.154v6.039H115.942zM115.942 346.301h274.154v6.039H115.942z"
      />
      <path
        d="M678.057 58.216c-10.813-18.27-32.205-19.121-32.205-19.121s-20.845-2.666-34.217 25.16c-12.463 25.934-29.665 50.975-2.769 57.047l4.858-15.121 3.009 16.246a105.236 105.236 0 0011.508.197c28.803-.93 56.234.272 55.35-10.064-1.173-13.74 4.87-36.765-5.534-54.344z"
        fill="#2f2e41"
      />
      <path
        d="M638.168 120.463c.053 2.056.037 4.225-.985 6.01-2.026 3.54-6.971 4.07-9.946 6.859-3.128 2.934-3.482 7.695-3.631 11.98-.156 4.482-.288 9.11 1.347 13.284a32.293 32.293 0 003.773 6.482q2.724 3.927 5.499 7.818a33.76 33.76 0 003.292 4.132c3.313 3.375 7.857 5.243 12.394 6.575 2.931.861 6.096 1.532 9.005.597a17.24 17.24 0 005.236-3.22 28.06 28.06 0 005.928-5.745c2.442-3.543 3.118-7.978 3.624-12.251a264.363 264.363 0 001.743-38.084 9.334 9.334 0 00-.727-4.046c-1.506-2.942-5.54-3.57-7.667-6.1-1.817-2.163-1.891-5.25-1.876-8.075l.04-7.192a3.013 3.013 0 00-.35-1.753 2.969 2.969 0 00-2.063-.962 69.55 69.55 0 00-13.531-.905c-3.672.112-10.718-.196-13.893 1.85-2.882 1.856.727 6.983 1.394 9.863a64.262 64.262 0 011.394 12.883z"
        fill="#ffb8b8"
      />
      <circle cx={646.597} cy={79.041} r={29.071} fill="#ffb8b8" />
      <path
        d="M640.013 141.912c-15.004-47.548-22.909 19.237-32.369 37.472.088 12.573.429 47.347 3.37 60.528 11.422 51.202 67.989 47.182 85 4 9.06-23.003-11.04-80.012-10.36-106.612-24.91-25.24-26.447 54.837-45.64 4.612z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M623.543 125.602c-32.512 15.802-17.001 58.256-28.786 86.04-9.412 38.119-33.348 50.105 21.38 50.993 11.792-34.843.274-72.69 16.352-108.168 1.107-13.895 5.873-19.12 5.45-30.888-3.151-4.883-11.318.51-14.396 2.023z"
        fill="#575a88"
      />
      <path
        fill="#2f2e41"
        d="M673.276 56.455l-21.134-11.071-29.185 4.529-6.038 26.67 15.031-.579 4.199-9.798v9.636l6.935-.266 4.027-15.599 2.515 16.606 24.657-.504-1.007-19.624z"
      />
      <path
        d="M660.204 138.985c16.733-1.953.42-22.049 13.701-21.707 39.771-6.69 51.67 17.943 36.544 52.324-13.864 27.538 35.18 66.221 7.782 81.63-9.724 3.57-23.648 14.797-33.151 9.909-15.257-38.348-15.809-82.163-24.876-122.156z"
        fill="#575a88"
      />
      <path
        d="M706.779 248.46c-5.826 2.786-10.435 7.217-14.335 12.365-.826 1.09.282 3.123 2.756 3.09 2.302-.032 3.677-2.984 5.98-3.015a99.973 99.973 0 003.805 18.146c4.593-4.034 7.343-9.711 9.974-15.228l5.201-10.906c1.91-4.003 3.82-8.009 5.541-12.097a8.728 8.728 0 00.854-3.05c.226-7.035-6.71-7.077-9.702-2.674-3.272 4.816-4.29 10.603-10.074 13.37z"
        fill="#ffb8b8"
      />
      <path
        d="M707.975 127.507c4.447 4.621 8.926 9.282 12.378 14.686s21.31 54.015 22.034 55.046c2.578 3.804-.434 13.64-.586 14.203-.298 1.766-4.023 19.403-4.957 23.298-.653 2.725-.645 5.563-1.072 8.333s-1.39 5.618-3.523 7.435a2.207 2.207 0 01-.97.543c-1.183.23-1.99-1.138-2.404-2.27-1.29-3.526-2.592-7.12-4.866-10.107s-5.72-5.326-9.474-5.314a5.055 5.055 0 01.367-4.613c.752-1.42 1.855-2.621 2.685-3.996a18.39 18.39 0 002.112-6.036 39.606 39.606 0 00-5.23-28.25 27.052 27.052 0 00-4.363-5.455c-1.833-1.717-3.978-3.098-5.67-4.955a19.373 19.373 0 01-4.512-10.947 57.72 57.72 0 01.318-12.01c.761-7.9 1.74-15.813 2.868-23.67a8.828 8.828 0 01.784-2.956c.886-1.653 2.384-2.167 4.081-2.965z"
        fill="#575a88"
      />
      <path
        d="M610.915 236.67c-2.991-4.404-9.928-4.36-9.701 2.674a8.728 8.728 0 00.853 3.05c1.721 4.088 3.631 8.093 5.54 12.097l5.202 10.906c2.631 5.517 5.382 11.194 9.974 15.227a99.973 99.973 0 003.806-18.145c2.302.031 3.678 2.983 5.98 3.014 2.474.033 3.581-2 2.756-3.09-3.9-5.147-8.51-9.578-14.336-12.364-5.783-2.766-6.802-8.554-10.074-13.37z"
        fill="#ffb8b8"
      />
      <path
        d="M623.875 132.05a8.828 8.828 0 01.783 2.957c1.129 7.857 2.107 15.769 2.868 23.67a57.72 57.72 0 01.318 12.01 19.373 19.373 0 01-4.511 10.947c-1.693 1.856-3.837 3.237-5.67 4.955a27.052 27.052 0 00-4.364 5.455 39.606 39.606 0 00-5.23 28.25 18.39 18.39 0 002.113 6.035c.83 1.375 1.932 2.577 2.684 3.996a5.055 5.055 0 01.368 4.613c-3.754-.011-7.2 2.328-9.475 5.315s-3.576 6.58-4.866 10.106c-.414 1.133-1.22 2.501-2.404 2.27a2.207 2.207 0 01-.97-.542c-2.133-1.818-3.095-4.666-3.522-7.436s-.42-5.607-1.073-8.333c-.933-3.894-4.659-21.531-4.957-23.297-.152-.564-3.163-10.4-.585-14.204.724-1.03 18.581-49.64 22.034-55.045s7.93-10.065 12.377-14.686c1.697.797 3.196 1.312 4.082 2.964z"
        fill="#575a88"
      />
    </svg>
  );
}
