import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Certification({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={816.362}
      height={662.161}
      viewBox="0 0 816.362 662.161"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{'certification'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path
        d="M550.011 608.1a836.665 836.665 0 01-113.616-7.445c-1.23-.157-2.475-.332-3.714-.517a696.226 696.226 0 01-27.483-4.467c-40.46-7.399-78.616-18.206-113.405-32.122-115.77-46.31-188.56-36.453-229.237-20.031-44.02 17.773-60.942 46.85-61.108 47.142L0 589.838c.168-.296 17.308-29.807 61.81-47.814 26.091-10.558 56.36-15.172 89.972-13.718 41.957 1.817 89.271 13.154 140.63 33.697 34.685 13.874 72.734 24.651 113.086 32.031a694.63 694.63 0 0027.423 4.456c1.233.184 2.468.358 3.694.515 102.082 14.042 200.56 5.573 265.2-4.002 53.28-7.893 87.569-17.12 87.91-17.213l.435 1.607c-.34.092-34.715 9.345-88.1 17.253A1049.775 1049.775 0 01550.01 608.1zM550.124 130.266c-29.349 0-57.922-1.373-85.489-4.119-63.346-6.309-121.499-19.92-172.842-40.458-115.77-46.31-188.56-36.454-229.237-20.031C18.536 83.43 1.614 112.508 1.448 112.8L0 111.978c.168-.296 17.308-29.806 61.81-47.814 26.092-10.558 56.36-15.174 89.972-13.718 41.957 1.816 89.271 13.154 140.63 33.697 115.952 46.381 244.98 48.204 332.802 41.571 95.19-7.192 163.828-25.599 164.51-25.784l.436 1.607c-.683.186-69.444 18.628-164.784 25.834-25.527 1.93-50.662 2.895-75.252 2.895z"
        fill="#3f3d56"
      />
      <path
        d="M545.813 8.05S513.082-3.64 492.04 43.12s-53.772 98.193-53.772 98.193l18.703 4.676s4.676-32.731 16.366-37.407l-4.676 42.083s140.277 49.097 203.401-4.676l-2.338-16.366s9.352 2.338 9.352 16.366l7.014-7.014s-7.014-14.028-28.055-32.731c-13.811-12.276-18.557-35.632-20.188-50.234a60.789 60.789 0 00-13.995-33.104c-13.983-16.205-39.463-33.43-78.039-14.856z"
        fill="#2f2e41"
      />
      <path
        d="M744.47 272.64s61.593 52.536 70.65 83.333-34.419 34.42-34.419 34.42l-94.202 9.058s-1.811 43.477-18.115 48.912-3.31-71.897-3.31-71.897l92.077-25.928-41.666-43.477zM456.43 326.988L449.184 423 409.33 586.042s-36.232 74.274-5.435 76.086 28.985-70.651 28.985-70.651l57.97-153.984 5.435-105.07z"
        fill="#fbbebe"
      />
      <circle cx={557.878} cy={98.73} r={59.782} fill="#fbbebe" />
      <path
        d="M530.705 127.715s21.738 77.898-12.681 97.825 86.955 34.42 119.563-25.362c0 0-57.97-19.927-41.666-72.463z"
        fill="#fbbebe"
      />
      <path
        d="M489.039 234.598l-10.87-3.623s-21.739 9.058-21.739 25.362-3.623 81.52-3.623 81.52l48.913 5.435z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M701.938 595.827c-66.876 9.906-164.346 17.907-265.429 4.004-.008-1-.008-1.973-.008-2.922q0-8.48.649-16.767c5.178-68.033 40.227-124.751 53.788-144.407a191.98 191.98 0 011.882-2.697c2.19-3.072 3.463-4.696 3.463-4.696s105.071-1.715 159.417 20.022a23.376 23.376 0 017.077 4.629c20.263 18.698 32.168 79.405 39.16 142.834z"
        fill="#2f2e41"
      />
      <path
        d="M662.95 209.236s23.55-12.681 47.1 14.493l47.1 54.347-36.23 39.854-68.84-41.666z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M493.279 99.11c21.163-15.317 38.294-32.958 49.627-53.942 0 0 38.838 43.153 62.572 45.31s2.158-51.784 2.158-51.784l-43.154-10.788-40.996 4.315-32.365 21.577z"
        fill="#2f2e41"
      />
      <path
        d="M628.53 196.555s-25.352 40.084-101.443 20.948l-43.179 11.891-5.739 1.58s5.435 108.695 19.927 128.622 10.87 30.797 7.247 32.608-12.681-1.811-7.247 7.247 12.681 3.623 5.435 9.057-10.87 25.362-10.87 25.362l170.288 21.74s-5.435-70.652 9.058-106.883 18.116-50.724 18.116-50.724l-10.87-90.579z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path fill="#e6e6e6" d="M41.517 161.09h233.102v291.378H41.517z" />
      <path
        fill="#fff"
        d="M57.751 185.649h49.951v33.3H57.751zM58.167 250.585h193.142v11.655H58.167zM58.167 281.388h193.142v11.655H58.167zM58.167 312.191h193.142v11.655H58.167zM58.167 342.994h193.142v11.655H58.167zM58.167 373.796h193.142v11.655H58.167z"
      />
      <path
        d="M320.407 452.052a45.66 45.66 0 11-7.076-24.459 45.783 45.783 0 017.076 24.46z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M313.331 427.593l-45.072 45.064c-2.833-4.386-18.615-24.767-18.615-24.767a63.866 63.866 0 016.46-5.703l13.087 17.45 38.862-38.862a45.599 45.599 0 015.278 6.818z"
        fill="#fff"
      />
    </svg>
  );
}
