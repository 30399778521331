import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function FeelingProud({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={711.188}
      height={669.683}
      viewBox="0 0 711.188 669.683"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{'feeling_proud'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path
        fill="#e6e6e6"
        d="M516.326 380.018v184.995l30.944 50.43 2.355 3.836h122.097l2.467-239.261H516.326z"
      />
      <path
        opacity={0.1}
        d="M516.326 380.018v184.995l30.944 50.43 1.899-235.425h-32.843z"
      />
      <path
        fill="#e6e6e6"
        d="M221.566 375.084V560.08l-30.944 50.43-2.355 3.835H66.17l-2.467-239.261h157.863z"
      />
      <path
        opacity={0.1}
        d="M221.566 375.084V560.08l-30.944 50.43-1.899-235.426h32.843z"
      />
      <path
        fill="#e6e6e6"
        d="M711.188 371.385v11.099H47.67v-16.033l61.665-32.066h547.588l54.265 37z"
      />
      <path
        opacity={0.1}
        d="M711.188 371.385v11.099H47.67v-16.033l663.518 4.934z"
      />
      <path
        fill="#3f3d56"
        d="M142.635 426.883h-29.6l-12.333-8.633h55.499l-13.566 8.633zM142.635 462.649h-29.6l-12.333-8.633h55.499l-13.566 8.633zM142.635 513.215h-29.6l-12.333-8.634h55.499l-13.566 8.634zM142.635 563.78h-29.6l-12.333-8.633h55.499l-13.566 8.633zM595.257 431.816h29.6l12.333-8.633h-55.499l13.566 8.633zM595.257 467.582h29.6l12.333-8.633h-55.499l13.566 8.633zM595.257 518.148h29.6l12.333-8.633h-55.499l13.566 8.633zM595.257 568.713h29.6l12.333-8.633h-55.499l13.566 8.633zM615.414 120.456H366.228a9.423 9.423 0 00-9.423 9.424v167.858a9.423 9.423 0 009.423 9.424h94.593l-3.544 22.626s-20.252 10.76-6.012 11.076 81.328 0 81.328 0 12.974 0-7.595-11.392l-3.334-22.31h93.75a9.423 9.423 0 009.424-9.424V129.88a9.423 9.423 0 00-9.424-9.424z"
      />
      <path fill="#f2f2f2" d="M365.823 130.424h249.996v139.871H365.823z" />
      <circle cx={490.821} cy={125.994} r={1.582} fill="#f2f2f2" />
      <circle cx={490.821} cy={294.346} r={6.013} fill="#f2f2f2" />
      <path
        fill="#3f3d56"
        d="M481.452 357.952v3.165h-170.25v-2.532l.234-.633 4.196-11.392h162.339l3.481 11.392zM546.97 354.854c-.31 1.326-1.481 2.725-4.127 4.047-9.493 4.747-28.797-1.265-28.797-1.265s-14.873-2.532-14.873-9.177a11.866 11.866 0 011.304-.776c3.991-2.111 17.225-7.322 40.69.22a9.786 9.786 0 014.46 2.924 4.667 4.667 0 011.343 4.027z"
      />
      <path
        d="M546.97 354.854c-11.62 4.452-21.978 4.785-32.607-2.598a23.343 23.343 0 00-13.886-4.573c3.991-2.111 17.225-7.322 40.69.22a9.786 9.786 0 014.46 2.924 4.667 4.667 0 011.343 4.027z"
        opacity={0.1}
      />
      <ellipse cx={532.4} cy={350.99} rx={4.114} ry={1.266} fill="#f2f2f2" />
      <path
        opacity={0.1}
        d="M481.452 357.952v3.165h-170.25v-2.532l.234-.633h170.016z"
      />
      <path
        d="M94.661 0a8.554 8.554 0 00-4.96 1.108c-1.477 1.011-2.407 2.782-3.357 4.43A52.986 52.986 0 0171.91 21.843c-4.289 3.142-9.742 7.06-9.008 12.852a17.372 17.372 0 002.09 5.426c3.957 7.987 14.754 14.159 13.692 23.273 3.931-6.581-1.327-9.835 2.604-16.416 1.872-3.134 5.124-6.672 7.95-4.704.947.66 1.569 1.841 2.554 2.42 2.351 1.38 4.853-1.258 6.934-3.127 7.177-6.442 17.383-4.755 26.314-2.747 4.216.948 8.847 2.19 11.34 6.22 3.276 5.299-3.114 11.02-4.729 17.011a3.264 3.264 0 003.503 4.062c2.68-.264 5.854-.48 6.013-1.657 3.372.12 7.503-.262 8.947-3.772a14.762 14.762 0 00.693-4.293c.53-5.898 3.033-11.278 4.691-16.89s2.38-12.19-.419-17.167a19.864 19.864 0 00-3.666-4.456C135.533 2.512 114.657-.022 94.661 0z"
        fill="#2f2e41"
      />
      <path
        d="M82.138 74.905s3.453 28.779-9.21 31.08 11.512 41.442 11.512 41.442l57.557 6.907-13.814-46.046s-9.209-3.453-3.453-26.476-42.592-6.907-42.592-6.907zM47.028 569.318l4.605 63.313h24.173L68.9 569.318H47.028zM224.303 449.6l3.453 52.952 26.477-11.511-10.361-43.743-19.569 2.302z"
        fill="#ffb8b8"
      />
      <path
        d="M29.761 327.58s1.151 66.766 8.058 88.637 5.756 23.023 4.605 26.476-2.303 1.151-2.303 6.907-2.302 96.695 0 104.753-6.907 21.872 0 23.023 39.139 0 40.29-6.907-9.209-9.209-4.604-13.813 11.51-98.998 11.51-98.998l16.117-65.615 29.93-34.534h63.312l19.57 75.975s-8.059 21.872-2.303 21.872 40.29 6.907 40.29-18.418-11.512-100.15-13.814-102.452 1.151-10.36-2.302-13.813-43.743-27.627-58.708-32.232-35.958-10.085-35.958-10.085zM254.233 481.832s-17.267-5.756-25.325 11.511-4.605 21.872-4.605 21.872 26.476 9.209 31.08 4.604c2.004-2.003 8.365-2.699 14.913-2.846 9.973-.224 12.627-14.333 3.208-17.619q-.422-.147-.853-.255c-9.21-2.302-18.418-17.267-18.418-17.267z"
        fill="#2f2e41"
      />
      <circle cx={108.038} cy={59.365} r={34.534} fill="#ffb8b8" />
      <path
        d="M63.144 120.375s56.406 11.511 70.22-6.907 19.569 51.801 19.569 51.801l6.906 73.673-10.36 40.29s-54.103 43.743-71.37 47.196-43.743 5.756-43.743 5.756 8.058-127.776 8.058-130.078 20.72-81.73 20.72-81.73z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M80.298 98.786s-12.55-7.19-16.003-.283-35.685 24.174-39.139 24.174 6.907 95.545 2.303 107.056S.983 325.277 13.645 332.184s3.453-6.907 16.116 10.36 74.824 17.267 78.277 10.36-27.627-58.707-21.871-107.055 14.964-115.114 6.906-124.323-12.775-22.74-12.775-22.74zM121.852 106.561l1.603-5.518s47.896 15.879 50.198 26.239 1.151 82.882-6.907 88.637-19.57 14.965-11.511 28.779 17.267 28.778 24.174 29.93 19.57 9.208 16.116 17.266-44.895-6.906-44.895-6.906-27.627-20.72-26.476-52.953-2.302-125.474-2.302-125.474z"
        fill="#2f2e41"
      />
      <path
        d="M162.142 242.396l-27.628 51.8s-40.29 41.442-17.267 46.046 35.686-37.987 35.686-37.987l29.93-42.592z"
        fill="#ffb8b8"
      />
      <path
        d="M96.344 9.036a7.838 7.838 0 00-4.039.783 9.413 9.413 0 00-2.732 3.131 39.773 39.773 0 01-11.75 11.524c-3.491 2.22-7.93 4.99-7.333 9.084a11.36 11.36 0 001.702 3.835 30.163 30.163 0 013.665 18.8l9.6-13.953c1.524-2.216 4.172-4.716 6.473-3.325.77.466 1.276 1.301 2.078 1.71 1.914.975 3.95-.89 5.644-2.21 5.843-4.553 14.15-3.36 21.421-1.942 3.432.67 7.202 1.549 9.231 4.397 3.372 4.733-.149 11.572 1.819 17.04a5.023 5.023 0 002.078-3.317c2.745.085 6.108-.185 7.284-2.666a9.188 9.188 0 00.563-3.035c.432-4.168 2.47-7.97 3.82-11.937s1.936-8.617-.342-12.134a14.681 14.681 0 00-2.984-3.149C129.616 10.811 112.62 9.02 96.344 9.036zM162.142 122.677l10.725 2.847s24.96 63.92 19.204 107.662-9.209 34.535-9.209 34.535-9.209-20.72-29.93-16.116zM48.623 624.263c-2.776 3.773-2.22 9.165-4.678 13.151-2.132 3.455-6.149 5.267-8.815 8.329a22.326 22.326 0 00-2.266 3.26c-2.473 4.142-4.483 9.451-2.048 13.616 1.96 3.35 6.078 4.66 9.855 5.552 4.775 1.128 9.735 2.03 14.564 1.169s9.549-3.82 11.27-8.413a32.5 32.5 0 011.206-3.4c2.617-5.153 10.827-5.209 13.506-10.33 1.875-3.584.151-7.917-1.571-11.576l-5.261-11.177c-1.746-3.71-8.824-1.576-12.514-2.465-4.812-1.16-9.786-2.436-13.248 2.284z"
        fill="#2f2e41"
      />
      <path
        d="M10.192 265.418l48.347 74.824s29.93 37.988 39.139 20.72-32.232-46.045-32.232-46.045l-31.08-57.557z"
        fill="#ffb8b8"
      />
      <path
        d="M34.366 124.98l-9.21-2.303s-18.418 5.756-23.022 29.93-1.151 120.87 4.604 122.02 28.428-16.836 32.057-13.023-7.883-15.755-4.43-26.115 0-110.51 0-110.51z"
        fill="#2f2e41"
      />
      <g data-name="Group 13" fill={props?.tertiary || '#6c63ff'}>
        <path
          data-name="Rectangle 55"
          d="M437.407 176.363h29.752v7.163h-29.752z"
        />
        <path
          data-name="Rectangle 56"
          d="M559.722 176.363h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 57"
          d="M580.659 176.363h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 58"
          d="M477.628 176.363h71.075v7.163h-71.075z"
        />
        <path
          data-name="Rectangle 59"
          d="M399.941 222.644h29.752v7.163h-29.752z"
        />
        <path
          data-name="Rectangle 60"
          d="M522.256 222.644h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 61"
          d="M543.193 222.644h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 62"
          d="M440.162 222.644h71.075v7.163h-71.075z"
        />
        <path
          data-name="Rectangle 63"
          d="M522.807 192.341h29.752v7.163h-29.752z"
        />
        <path
          data-name="Rectangle 64"
          d="M563.028 192.341h29.752v7.163h-29.752z"
        />
        <path
          data-name="Rectangle 66"
          d="M399.941 192.341h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 67"
          d="M420.878 192.341h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 68"
          d="M441.815 192.341h71.075v7.163h-71.075z"
        />
        <path
          data-name="Rectangle 69"
          d="M461.099 207.768h29.752v7.163h-29.752z"
        />
        <path
          data-name="Rectangle 70"
          d="M420.878 207.768h29.752v7.163h-29.752z"
        />
        <path
          data-name="Rectangle 71"
          d="M399.941 207.768h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 73"
          d="M582.312 207.768h10.468v7.163h-10.468z"
        />
        <path
          data-name="Rectangle 74"
          d="M500.768 207.768h71.075v7.163h-71.075z"
        />
      </g>
    </svg>
  );
}
