import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Books({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={737.666}
      height={594}
      viewBox="0 0 737.666 594"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M268.426 450.574a24.3 24.3 0 019.743-30.085c1.648-1.002.142-3.598-1.514-2.59a27.008 27.008 0 00-10.82 34.189c.758 1.77 3.343.243 2.591-1.514z"
        fill="#3f3d56"
      />
      <path
        d="M269.284 454.609c-4.046-7.169-3.094-15.747-1.638-23.5 1.519-8.092 3.51-16.308 1.787-24.538a22.527 22.527 0 00-5.353-10.734c-1.31-1.424-3.427.702-2.121 2.121 5.089 5.527 5.679 13.545 4.853 20.666-.973 8.398-3.732 16.582-3.39 25.117a27.202 27.202 0 003.272 12.382 1.501 1.501 0 002.59-1.514z"
        fill="#3f3d56"
      />
      <path
        d="M291 477.5h-51a4.505 4.505 0 01-4.5-4.5v-14.98a14.536 14.536 0 0114.52-14.52h7.48a1.502 1.502 0 001.5-1.5v-7a2.503 2.503 0 012.5-2.5h8a2.503 2.503 0 012.5 2.5v7a1.502 1.502 0 001.5 1.5h7.48a14.536 14.536 0 0114.52 14.52V473a4.505 4.505 0 01-4.5 4.5z"
        fill="#ccc"
      />
      <path
        d="M266.284 387.54c1.074 5.698-.256 10.733-2.97 11.244s-5.783-3.694-6.857-9.393 2.228-15.175 2.228-15.175 6.525 7.625 7.599 13.324zM286.877 414.052c-3.4 4.697-7.97 7.193-10.207 5.573s-1.293-6.74 2.107-11.437 12.553-8.813 12.553-8.813-1.052 9.98-4.453 14.677z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M174.426 90.574a24.3 24.3 0 019.743-30.085c1.648-1.002.142-3.598-1.514-2.59a27.008 27.008 0 00-10.82 34.189c.758 1.77 3.343.243 2.591-1.514z"
        fill="#3f3d56"
      />
      <path
        d="M175.284 94.609c-4.046-7.169-3.094-15.747-1.638-23.5 1.519-8.092 3.51-16.308 1.787-24.538a22.527 22.527 0 00-5.353-10.734c-1.31-1.423-3.427.702-2.121 2.121 5.089 5.527 5.679 13.545 4.853 20.666-.973 8.398-3.732 16.582-3.39 25.117a27.202 27.202 0 003.272 12.382 1.501 1.501 0 002.59-1.514z"
        fill="#3f3d56"
      />
      <path
        d="M197 117.5h-51a4.505 4.505 0 01-4.5-4.5V98.02a14.536 14.536 0 0114.52-14.52h7.48A1.502 1.502 0 00165 82v-7a2.503 2.503 0 012.5-2.5h8A2.503 2.503 0 01178 75v7a1.502 1.502 0 001.5 1.5h7.48a14.536 14.536 0 0114.52 14.52V113a4.505 4.505 0 01-4.5 4.5z"
        fill="#ccc"
      />
      <path
        d="M172.284 27.54c1.074 5.698-.256 10.733-2.97 11.244s-5.783-3.694-6.857-9.393 2.228-15.175 2.228-15.175 6.525 7.625 7.599 13.324zM192.877 54.052c-3.4 4.697-7.97 7.193-10.207 5.573s-1.293-6.74 2.107-11.437 12.553-8.813 12.553-8.813-1.052 9.98-4.453 14.677zM32 272h41v15H32zM32 321h41v15H32z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M323 593H17a17.02 17.02 0 01-17-17V17A17.02 17.02 0 0117 0h306a17.02 17.02 0 0117 17v559a17.02 17.02 0 01-17 17zM17 2A15.017 15.017 0 002 17v559a15.017 15.017 0 0015 15h306a15.017 15.017 0 0015-15V17a15.017 15.017 0 00-15-15z"
        fill="#3f3d56"
      />
      <path
        fill="#3f3d56"
        d="M1 115.453h338v2H1zM1 235.484h338v2H1zM1 355.516h338v2H1zM1 475.547h338v2H1z"
      />
      <path
        d="M70 358H36a5.006 5.006 0 01-5-5v-97a5.006 5.006 0 015-5h34a5.006 5.006 0 015 5v97a5.006 5.006 0 01-5 5zM36 253a3.003 3.003 0 00-3 3v97a3.003 3.003 0 003 3h34a3.003 3.003 0 003-3v-97a3.003 3.003 0 00-3-3z"
        fill="#3f3d56"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M95 272h41v15H95zM95 321h41v15H95z"
      />
      <path
        d="M133 358H99a5.006 5.006 0 01-5-5v-97a5.006 5.006 0 015-5h34a5.006 5.006 0 015 5v97a5.006 5.006 0 01-5 5zM99 253a3.003 3.003 0 00-3 3v97a3.003 3.003 0 003 3h34a3.003 3.003 0 003-3v-97a3.003 3.003 0 00-3-3z"
        fill="#3f3d56"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M195 151h41v15h-41zM195 200h41v15h-41z"
      />
      <path
        d="M233 237h-34a5.006 5.006 0 01-5-5v-97a5.006 5.006 0 015-5h34a5.006 5.006 0 015 5v97a5.006 5.006 0 01-5 5zm-34-105a3.003 3.003 0 00-3 3v97a3.003 3.003 0 003 3h34a3.003 3.003 0 003-3v-97a3.003 3.003 0 00-3-3z"
        fill="#3f3d56"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M258 151h41v15h-41zM258 200h41v15h-41z"
      />
      <path
        d="M296 237h-34a5.006 5.006 0 01-5-5v-97a5.006 5.006 0 015-5h34a5.006 5.006 0 015 5v97a5.006 5.006 0 01-5 5zm-34-105a3.003 3.003 0 00-3 3v97a3.003 3.003 0 003 3h34a3.003 3.003 0 003-3v-97a3.003 3.003 0 00-3-3z"
        fill="#3f3d56"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M147.91 277.464l38.38-14.422 5.276 14.042-38.38 14.422zM165.146 323.332l38.38-14.422 5.276 14.042-38.38 14.422z"
      />
      <path
        d="M180.144 356.883a5.009 5.009 0 01-4.678-3.244l-34.12-90.801a5.006 5.006 0 012.921-6.44l31.828-11.959a5 5 0 016.439 2.922l34.12 90.801a5.006 5.006 0 01-2.921 6.44l-31.828 11.959a4.996 4.996 0 01-1.761.322zm-2.289-110.765a3.006 3.006 0 00-1.057.194l-31.827 11.959a3.004 3.004 0 00-1.753 3.864l34.12 90.8a3 3 0 003.864 1.753l31.827-11.959a3.004 3.004 0 001.753-3.864l-34.12-90.8a3.006 3.006 0 00-2.807-1.947z"
        fill="#3f3d56"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M9.91 158.464l38.38-14.422 5.276 14.042-38.38 14.422zM27.146 204.332l38.38-14.422 5.276 14.042-38.38 14.422z"
      />
      <path
        d="M42.144 237.883a5.009 5.009 0 01-4.678-3.244l-34.12-90.801a5.006 5.006 0 012.921-6.44l31.828-11.959a5 5 0 016.439 2.922l34.12 90.801a5.006 5.006 0 01-2.921 6.44L43.905 237.56a4.996 4.996 0 01-1.761.322zm-2.289-110.765a3.006 3.006 0 00-1.057.194L6.971 139.27a3.004 3.004 0 00-1.753 3.864l34.12 90.8a3 3 0 003.864 1.753l31.827-11.959a3.004 3.004 0 001.753-3.864l-34.12-90.8a3.006 3.006 0 00-2.807-1.947z"
        fill="#3f3d56"
      />
      <circle cx={627} cy={219} r={31} fill="#2f2e41" />
      <path
        d="M489.28 360.471a10.743 10.743 0 0014.424-7.956l85.237-53.778-15.514-17.467-78.943 58.39a10.8 10.8 0 00-5.204 20.811z"
        fill="#ffb8b8"
      />
      <path
        d="M571.146 313.034a4.504 4.504 0 01-3.793-2.063l-9.87-15.34a4.5 4.5 0 011.148-6.081l33.03-23.873a14.789 14.789 0 1116.63 24.445l-34.73 22.205a4.466 4.466 0 01-2.415.707z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M585.894 580.85l-12.259-.001-5.833-47.288 18.095.001-.003 47.288z"
      />
      <path
        d="M564.877 577.346h23.644v14.887h-38.53a14.887 14.887 0 0114.886-14.887z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb8b8"
        d="M643.128 571.684l-11.804 3.314-18.398-43.951 17.421-4.891 12.781 45.528z"
      />
      <path
        d="M621.947 573.992l22.763-6.39 4.024 14.332-37.097 10.414a14.887 14.887 0 0110.31-18.356z"
        fill="#2f2e41"
      />
      <path
        d="M676.16 499.113l-47.596-56.17 28.881-35.045a4.5 4.5 0 016.961.02l43.702 53.635z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M630.484 443.36a15.942 15.942 0 012.586-3.453l27.568-27.947a6.134 6.134 0 014.075-1.83c3.15-.149 8.316.17 9.756 3.625l-37.218 30.451z"
        fill="#f2f2f2"
      />
      <path
        d="M677.814 510.999a11.43 11.43 0 01-5.958-1.887l-.131-.135-43.592-66.67 7.233-.699 38.767-30.238a4.5 4.5 0 016.522 1.067l42.376 64.138a4.513 4.513 0 01-1.238 6.211l-41.006 27.65a7.949 7.949 0 01-2.973.563z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M662.397 465.394a2.5 2.5 0 01-2.078-1.103l-5.007-7.438a2.504 2.504 0 01.677-3.471l25.691-17.296a2.505 2.505 0 013.47.678l5.008 7.438a2.504 2.504 0 01-.678 3.47l-25.69 17.297a2.49 2.49 0 01-1.393.425z"
        fill="#3f3d56"
      />
      <path
        d="M565.862 573.698a5.48 5.48 0 01-5.438-5.004c-2.182-24.785-13.127-149.7-14.329-174.926-1.334-28.03 21.993-72.372 22.229-72.816l.161-.305 11.53 1.44v1.342l10.975 1.29 21.238 2.056 6.08 74.319 2.689 50.402 25.259 102.943a4.476 4.476 0 01-3.186 5.415l-21.984 5.995a4.52 4.52 0 01-5.512-3.11l-20.698-72.707a1.5 1.5 0 00-2.941.457l2.189 70.896a5.343 5.343 0 01-1.618 3.912c-5.039 4.732-18.353 7.259-25.841 8.344a5.579 5.579 0 01-.803.057z"
        fill="#2f2e41"
      />
      <path
        d="M631.918 261.392c-3.488-3.636-26.473 1.361-31.576 6.719l-34.935 57.106 57.778 10.75 12.765-72.559z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M616.774 406.48a11.248 11.248 0 01-3.657-.61c-3.357-1.151-7.47-2.356-12.228-3.579-20.833-5.357-28.189-22.51-21.862-50.98l.16-.723a210.734 210.734 0 0132.957-75.263l2.45-3.52a26.398 26.398 0 0127.045-10.755c9.374 1.933 14.407 4.735 14.96 8.328 1.085 7.05-19.854 92.27-28.907 128.582a11.235 11.235 0 01-10.918 8.52z"
        fill="#2f2e41"
      />
      <path
        d="M545.004 395.54a5.283 5.283 0 01-1.605-.25l-6.753-2.161a5.269 5.269 0 01-3.24-7.082l35.83-84.104a78.693 78.693 0 0134.124-37.827 1.841 1.841 0 011.462-.137 1.657 1.657 0 011.005.892c4.214 9.27-18.723 53.144-37.152 88.396-9.438 18.05-17.588 33.64-18.516 38.086a5.263 5.263 0 01-5.155 4.186z"
        fill="#2f2e41"
      />
      <path
        d="M655.859 414.703a10.743 10.743 0 00.921-16.446l-.285-100.784-23.04 3.865 7.536 97.902a10.8 10.8 0 0014.868 15.463z"
        fill="#ffb8b8"
      />
      <path
        d="M637.1 322.477a4.5 4.5 0 01-4.49-4.204l-2.68-40.666a14.807 14.807 0 0113.785-15.73 14.79 14.79 0 0115.76 14.626l.365 41.22a4.509 4.509 0 01-4.447 4.54l-18.24.214z"
        fill="#2f2e41"
      />
      <circle cx={620.614} cy={227.016} r={24.561} fill="#ffb8b8" />
      <path
        d="M641.036 244.202c1.831-5.83 3.513-12.703-.112-17.623-2.612-3.544-7.302-4.916-11.7-5.113s-8.81.55-13.2.2-9.028-2.08-11.257-5.877c-2.891-4.923-.443-11.704 4.205-15.02s10.789-3.778 16.446-3.006a35.477 35.477 0 0119.292 8.63c5.258 4.853 8.685 11.848 8.533 19.002s-4.165 14.277-10.56 17.49"
        fill="#2f2e41"
      />
      <path
        d="M634.912 190.534c-5.88-1.811-8.592-9.585-5.963-15.147s9.351-8.475 15.454-7.7 11.482 4.69 15.191 9.599 5.947 10.759 7.812 16.622 3.416 11.852 5.963 17.453a54.168 54.168 0 0044.45 30.942c4.13.3 8.414.085 12.177-1.64s6.919-5.206 7.34-9.325c2.142 14.63-6.368 30.176-19.846 36.255s-30.764 2.169-40.313-9.12c-9.33-11.03-10.776-26.605-10.551-41.052.061-3.961.207-7.998-.887-11.805s-3.685-7.43-7.458-8.638c-3.044-.976-6.34-.273-9.462.419s-6.435 1.358-9.456.315-5.455-4.48-4.238-7.435"
        fill="#2f2e41"
      />
      <path d="M732.083 594h-264a1 1 0 110-2h264a1 1 0 010 2z" fill="#ccc" />
    </svg>
  );
}
