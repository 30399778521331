// @ts-nocheck
import React from 'react';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {StyleSheet, Text, View} from 'react-native';
import 'rsuite/dist/styles/rsuite-default.css';
import {MobileViewV} from './components/MobileView';
import {WebViewV} from './components/WebView';

type product = {
  image?: string;
  title?: string;
  subTitle?: string;
  link?: string;
};

export type cardVprops = {
  productData?: product;
  verifyData?: {label: string; value: string}[];
  appearance: 'web' | 'mobile';
  iconStyles?: any;
  imgStyle?: any;
  contentPos?: any;
  panelStyle?: any;
  titleStyle?: any;
  subTitleStyle?: any;
  hrLineStyle?: any;
  labelPosStyle?: any;
  labelStyle?: any;
  valueStyle?: any;
  iconSize?: string;
  primaryColor?:string;
};

export const VerifyCardV = (props: cardVprops) => {
  const {
    productData,
    verifyData,
    imgStyle,
    hrLineStyle,
    iconStyles,
    contentPos,
    panelStyle,
    titleStyle,
    subTitleStyle,
    labelPosStyle,
    imgHeight,
    hrefLink,
    labelStyle,
    valueStyle,
    icon,
    primaryColor,
  } = props;

  const styles = {
    containerStyle: {
      justifyContent: 'center',
      // marginTop: '50px',
    },
    titleStyle: {
      paddingTop: 45,
    },
    subTitleStyle: {
      paddingBottom: 15,
      fontSize: 20,
    },
    iconStyle: {
      paddingRight: 10,
    },
    labelStyle: {
      display: 'flex',
    },
    valueStyle: {
      display: 'block',
    },
  };
  const data: any = [];

  const output: any = [];
  const getCard = () => {
    if (props?.appearance === 'web') {
      return <WebViewV {...props} />;
    }
    if (props?.appearance === 'mobile') {
      return <MobileViewV {...props} />;
    }
    // eslint-disable-next-line no-unused-expressions
    // return (
    //   <View>
    //     <Panel
    //       shaded
    //       bordered
    //       bodyFill
    //       style={StyleSheet.flatten([panelStyle])}
    //     >
    //       <Panel>
    //         <View style={StyleSheet.flatten([imgStyle])}>
    //           <AvatarV
    //             source={{uri: productData?.image}}
    //             avatarStyle={{resizeMode: 'contain'}}
    //             containerStyle={{
    //               width: '100%',
    //               height: 200,
    //             }}
    //           />
    //         </View>
    //         <View style={StyleSheet.flatten([contentPos])}>
    //           <Text style={StyleSheet.flatten([titleStyle, styles.titleStyle])}>
    //             {productData?.title}
    //           </Text>
    //           <br />
    //           {productData?.link ? (
    //             <View
    //               style={{
    //                 display: 'flex',
    //                 flexDirection: 'row',
    //                 alignItems: 'flex-start',
    //                 justifyContent: 'center',
    //               }}
    //             >
    //               <Icon
    //                 style={StyleSheet.flatten([iconStyles, styles.iconStyle])}
    //                 icon="globe"
    //                 size={'2x'}
    //               />
    //               <a
    //                 href={productData?.link}
    //                 style={{textDecoration: 'underline'}}
    //               >
    //                 <Text
    //                   style={StyleSheet.flatten([
    //                     subTitleStyle,
    //                     styles.subTitleStyle,
    //                   ])}
    //                 >
    //                   {productData?.subTitle}
    //                 </Text>
    //               </a>
    //             </View>
    //           ) : null}
    //           <Divider />
    //           {/* <hr style={StyleSheet.flatten([hrLineStyle])} /> */}
    //           {getContent()}
    //         </View>
    //       </Panel>
    //     </Panel>
    //   </View>
    // );
  };
  return (
    <FBGridV container justify={'center'} align={'middle'}>
      <FBItemV colspan={22}>
        <View style={StyleSheet.flatten(styles.containerStyle)}>
          {getCard()}
        </View>
      </FBItemV>
    </FBGridV>
  );
};
