import React, {useContext} from 'react';
import {View, StyleSheet, Text} from 'react-native';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import {AvatarV, AvatarSize} from 'react-native-avatar-vfw';
import {ImageV} from 'react-native-image-vfw';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../../../../providers/theme';
import {IProps} from './index';

export const HeaderSM = (props: IProps) => {
    const { width, height } = useViewport();
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const styles = {
        headerStyle: {
            backgroundColor: '#ECECEC',
            width: width * 0.99,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: '5%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom:'2%'
        }
    }
    return(
        <View style={StyleSheet.flatten([styles.headerStyle])}>
            <div style={{cursor:'pointer'}} onClick={()=>history.push('/')}>
            <ImageV
                imageStyle={{width:165, height:70, resizeMode:'contain'}}
                source={{uri:props?.headerLogo}}        
            />
            </div>
        </View>
    )
}