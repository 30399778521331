import React from 'react';
import { ProfileTemplateV } from 'react-profile-template-rs';
import { FooterDetail } from './Footer';
import { Header } from './Header';
import { StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import config from '../config/default-blueprint.json';
import {VerifyCardV} from '../../../widgets/VerifierCardV';
const BP = config?.verifierDetails;
import { IProps } from './index';

export const VerifierSM = (props: IProps) => {
    const history = useHistory();
    const styles = StyleSheet.create({
        bgStyle: {
            backgroundAttachment: 'scroll',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }
    });

    const onFooterNavSelect = (eventKey: string) => {
        if (eventKey === 'terms') {
            history.push('/termsandcondition');
            window.scrollTo(0, 0);
        }
        if (eventKey === 'privacy') {
            history.push('/privacy');
            window.scrollTo(0, 0)
        }
        if (eventKey === 'aboutus') {
            history.push('/hello')
        }
    }
    const _cred = props.verifierDetails?.credentialDetails?.map((item: any) => (
        {
            label: item?.title,
            value: item?.subTitle,
            link: item?.link
        }
    ));

    let productData ={
        image: props.verifierDetails?.avatar,
        title: props.verifierDetails?.name,
        subTitle: props.verifierDetails?.docNumber,
        link: props.verifierDetails?.docLink,
    }

    return (
        <>
            <Header
                headerLogo={BP?.headerLogo}
            />
            {/* <ImageBackgroundV
        image={MOBILE_BACKGROUND_IMAGE}
        height={'100%'}
        width={'100%'}
        imageBackgroundStyle={StyleSheet.flatten([styles.bgStyle])}
        > */}
        <VerifyCardV
                    
                    productData={productData}
                    verifyData={_cred}
                    appearance={'mobile'}
                />
            {/* <ProfileTemplateV
                avatar={props.verifierDetails?.avatar}
                name={props.verifierDetails?.name}
                docNumber={props.verifierDetails?.docNumber}
                docLink={props.verifierDetails?.docLink}
                profileDetails={_cred}
                numberOfCol={'single'}
                docNameLeftStyle={{ fontWeight: '500' }}
            // backgroundImage={PROFILE_CARD_BG}
            /> */}
            {/* </ImageBackgroundV> */}
            <FooterDetail
                onFooterNavSelect={onFooterNavSelect}
            />
        </>
    );
}