import React, {ReactNode} from 'react';
import {FBGridV} from 'react-flexbox-grid-rs';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useViewport} from 'react-viewport-provider';

export interface IIllustratorProps {
  containerStyle?: ViewStyle;
  illustratorsGroup?: TIllustratorGroup[];
  renderIllustrator(props: TRenderIllustratorProps): ReactNode | null;
}

export type TIllustratorGroup = {
  justify?: 'center' | 'start' | 'end' | 'space-around' | 'space-between';
  align?: 'top' | 'middle' | 'bottom';
  style?: ViewStyle;
  illustrators: TIllustrator[];
};

export type TIllustrator = {
  colspan: number;
  width: number;
  height: number;
  stroke?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
  key: string;
};

export type TRenderIllustratorProps = {
  width: number;
  height: number;
  stroke?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
  key: string;
  index: number;
};

export const IllustratorV = (props: IIllustratorProps) => {
  const {bp} = useViewport();
  const renderIllustratorGroup = () => {
    const _illustratorGroups: ReactNode[] = [];
    if (
      props.illustratorsGroup &&
      Array.isArray(props.illustratorsGroup) &&
      props.illustratorsGroup.length > 0
    ) {
      props.illustratorsGroup.forEach(
        (illustratorGroup: TIllustratorGroup, index: number) => {
          if (illustratorGroup) {
            _illustratorGroups.push(
              <FBGridV
                justify={illustratorGroup.justify ?? 'center'}
                align={illustratorGroup.align ?? 'middle'}
                style={StyleSheet.flatten([illustratorGroup.style])}
                key={`illustrator-group-${index}`}
              >
                {renderIllustrator(illustratorGroup.illustrators)}
              </FBGridV>,
            );
          }
        },
      );
    }
    return _illustratorGroups;
  };
  const renderIllustrator = (illustrators: TIllustrator[]) => {
    const _illustrator: ReactNode[] = [];
    if (
      props.renderIllustrator &&
      illustrators &&
      Array.isArray(illustrators) &&
      illustrators.length > 0
    ) {
      illustrators.forEach((illustrator: TIllustrator, index: number) => {
          _illustrator.push(
            props.renderIllustrator({
              key: illustrator.key,
              width: illustrator.width,
              height: illustrator.height,
              stroke: illustrator.stroke,
              main: illustrator.main,
              highlight: illustrator.highlight,
              secondary: illustrator.secondary,
              tertiary: illustrator.tertiary,
              index: index,
            }),
          );
      });
    }
    return _illustrator;
  };
  return (
    //@ts-ignore
    <View
      style={StyleSheet.flatten([styles.containerStyle, props.containerStyle])}
    >
      {renderIllustratorGroup()}
    </View>
  );
};

const styles = {
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
