import moment from 'moment';
import React from 'react';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {StyleSheet, Text, View} from 'react-native';
import {AvatarV} from 'react-native-avatar-vfw';
import {NavV} from 'react-nav-rs';
import {Icon} from 'rsuite';
import Avatar from 'rsuite/lib/Avatar';
import Panel from 'rsuite/lib/Panel';
import {LandingPageProps} from '../LandingPageV';

export const FooterV = (props: LandingPageProps) => {
  const styles = {
    panalStyle: {
      backgroundColor: '#fff',
    },
    copyrightpanalStyle: {
      backgroundColor: '#f0f0f0',
      margin: '0 auto',
      justifyContent: 'center',
      alignSelf: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    titleStyle: {
      display: 'block',
      fontSize: 16,
      fontWeight: 700,
      textAlign: 'center',
      paddingTop: 20,
    },
    contactItemStyle: {
      padding: 5,
    },
    iconStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  };

  const onFooterNavSelect = (eventKey: string) => {
    // console.log('eventkey', eventKey);
    props?.onFooterNavSelect && props?.onFooterNavSelect(eventKey);
  };
  const _getcontactDetails = () => {
    return (
      <>
        {Array.isArray(props?.contactDetails?.details) &&
          props?.contactDetails?.details.map(item => {
            if (item.key === 'phone') {
              return (
                <View style={StyleSheet.flatten([styles.contactItemStyle])}>
                  <Text
                    style={{textAlign: 'center', fontSize: 12, color: '#fff'}}
                  >
                    <a href={`tel:${item?.value}`}>{item.value}</a>
                  </Text>
                </View>
              );
            }
            if (item.key === 'email') {
              return (
                <View style={StyleSheet.flatten([styles.contactItemStyle])}>
                  <Text
                    style={{textAlign: 'center', fontSize: 12, color: '#fff'}}
                  >
                    <a href={`mailto:${item?.value}`}>{item.value}</a>
                  </Text>
                </View>
              );
            }
          })}
      </>
    );
  };

  const _getSocialLinks = () => {
    return (
      <View style={StyleSheet.flatten([styles.iconStyle])}>
        {Array.isArray(props?.socialLinks?.details)
          ? props?.socialLinks?.details.map((item, key) => {
              return (
                <a href={item.link} target="_blank" style={{padding: 5}}>
                  <AvatarV source={{uri: item.icon}} rounded />
                </a>
              );
            })
          : null}
      </View>
    );
  };

  return (
    <FBGridV justify="center">
      <FBItemV colspan={24}>
        {props?.contactDetails ? (
          <Panel style={StyleSheet.flatten([styles.panalStyle])}>
            <Text style={StyleSheet.flatten([styles.titleStyle])}>
              {props?.contactDetails?.title}
            </Text>
            {_getcontactDetails()}
            <Text style={StyleSheet.flatten([styles.titleStyle])}>
              {props?.socialLinks?.title}
            </Text>
            {_getSocialLinks()}
          </Panel>
        ) : null}

        <Panel style={StyleSheet.flatten([styles.copyrightpanalStyle])}>
          <div
            style={{
              width: '100%',
              margin: '0 auto',
              display: 'block',
              justifyContent: 'center',
              alignSelf: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar src={props?.copyright?.logo} circle />
          </div>
          <NavV
            items={props?.copyright?.footerNav}
            appearance={'subtle'}
            onSelect={onFooterNavSelect}
            style={{width: '100%', display: 'block', margin: '0 auto'}}
          />
          <Text>
            <Icon icon={'copyright'} /> {moment().year()}{' '}
            {props?.copyright?.copyRightText?.company} {'All rights reserved.'}
          </Text>
          {props?.copyright?.copyRightText?.poweredBy ? (
            <Text>
              {' powered by'}{' '}
              <a
                href={props?.copyright?.copyRightText?.poweredByURL}
                target="_blank"
              >
                {' '}
                {props?.copyright?.copyRightText?.poweredBy}
              </a>
            </Text>
          ) : null}
        </Panel>
      </FBItemV>
    </FBGridV>
  );
};
