import React, { useState, useEffect } from 'react';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { provVerifier } from './provider';
import { Verifier } from './components';
import { useHistory } from 'react-router';
import Alert from 'rsuite/lib/Alert';
import { LandingPage } from './components/LandingScreen';
import { HttpServer } from '../../servers/HttpServer';
import config from '../../config/default.config.json';
import { useViewport } from 'react-viewport-provider';
const { verifyEP, apiEP, httpHost } = config?.servers?.http;
const http = new HttpServer(45000);

export const VerifierView = () => {
    const history = useHistory();
    const { bp } = useViewport();
    const { verifierDetails } = provVerifier();
    const [detail, setDetail] = useState<any>();
    const [url, setUrl] = useState('');
    useEffect(() => {
        const url = window.location.href;
        _getVerifyData(url)
    }, []);

    const _getVerifyData = (url: string) => {

        setUrl(url);
        // let params = url.split("/").slice(-2);
        let params = url.split("?")[0].split("/").slice(-2);
        console.log('params', params);
        console.log('breakpoints', bp);
        let getURL;
        // if(bp === 'xs'){
        //      getURL =encodeURI(`${httpHost}${apiEP}${verifyEP}?network_operator_name=${params[0]}&credential_id=${params[1]}&isImageRequired=${true}&isDataRequired=${true}&isPdfRequired=${false}`);
        // }
        // else {
        //     getURL =encodeURI(`${httpHost}${apiEP}${verifyEP}?network_operator_name=${params[0]}&credential_id=${params[1]}&isImageRequired=${false}&isDataRequired=${true}&isPdfRequired=${true}`)
        // }
        // getURL = encodeURI(`${httpHost}?network_operator_name=${params[0]}&credential_id=${params[1]}&isImageRequired=${false}&isDataRequired=${true}&isPdfRequired=${false}`)
        http.post(httpHost,{tag:params[1]}).then((res) => {
            console.log(`data in http post`, res);
            if (res && res?.data) {
                setDetail(res?.data)
            }
        }).catch(err => {
            Alert.error('Error in validating the blockchain proof point', 10000);
            history.push('/');
        });
    }
    // console.log('verifier Data', verifierDetails);
    const _renderBody = () => {
        
        if (detail) {
            // let _newDetails={
            //     ...detail,
            //     credentialDetails:detail.verify_details,
            //     footerContent: detail?.static?.footerContent,
            //     share:detail?.static?.share
            // }
            return <Verifier
                verifierDetails={detail}
            />;
        }
        return (
            <LandingPage />
        )

    };

    return <>{_renderBody()}</>;
};