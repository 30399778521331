import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Professor({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={583.904}
      height={652}
      viewBox="0 0 583.904 652"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{'modern professional'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <circle
        cx={137.032}
        cy={553.164}
        r={14.92}
        fill={props?.tertiary || '#6c63ff'}
        opacity={0.4}
      />
      <circle
        cx={109.419}
        cy={560.29}
        r={10.466}
        fill={props?.tertiary || '#6c63ff'}
        opacity={0.4}
      />
      <path
        d="M531.002 349.261A255.44 255.44 0 00513.1 124.494L105 181l391.09-81.506A254.562 254.562 0 00294 0C153.167 0 39 114.167 39 255a257.19 257.19 0 002.373 34.797zM56.045 346.81C92.903 442.274 185.535 510 294 510c101.778 0 189.617-59.634 230.515-145.861z"
        fill="#f2f2f2"
      />
      <circle cx={106} cy={112} r={12} fill="#3f3d56" />
      <circle cx={538} cy={252} r={12} fill={props?.tertiary || '#6c63ff'} />
      <path fill={props?.tertiary || '#6c63ff'} d="M105 339h20v20h-20z" />
      <path d="M268 193s33-14.38 48.5.81S275 204 268 193z" fill="#3f3d56" />
      <path
        d="M324.982 124.805c-11.09-18.739-33.03-19.612-33.03-19.612s-21.38-2.734-35.094 25.805c-12.784 26.6-30.427 52.284-2.84 58.51L259 174l3.086 16.663a107.934 107.934 0 0011.803.202c29.542-.954 57.676.279 56.77-10.322-1.204-14.092 4.994-37.708-5.677-55.738z"
        fill="#2f2e41"
      />
      <ellipse cx={294.5} cy={398} rx={56} ry={13} fill="#3f3d56" />
      <path
        fill="#2f2e41"
        d="M285.426 561.5l54-3.994.148 1.995-54 3.993zM249.018 552.376l.964-1.753 20 11-.964 1.753zM288.5 543.5h18v2h-18zM241.498 650.568l-1.996-.136 15-232 1.996.136-15 232z"
      />
      <path
        fill="#2f2e41"
        d="M315.501 650.549l-10-228 1.998-.098 10 228-1.998.098zM333.504 420.582l1.993-.165 19 230-1.994.165z"
      />
      <path
        d="M340.5 464.5s-14 26-23 26-8 17-6 21 7 13-1 26c-2.794 4.54-2.295 9.692-.46 14.473 5.137 13.395 25.123 9.336 24.486-4.996q-.011-.237-.026-.477c-1-16 7-41 15-47s15-22 15-22zM244.5 458.5s14 26 23 26 8 17 6 21-7 13 1 26c2.794 4.54 2.295 9.692.46 14.473-5.137 13.395-25.123 9.336-24.486-4.996q.011-.237.026-.477c1-16-7-41-15-47s-15-22-15-22z"
        fill="#ffb8b8"
      />
      <path
        d="M255.5 330.5l-6 13s-88 29-80 63 47 87 53 85 38-8 39-25-23-47-23-47l33-30 48.02-1 40.98 31s-43 38-24 53 47 25 51 19 49-95 42-103-78-38-78-38l-19-27-74-4z"
        fill="#2f2e41"
      />
      <path
        d="M273.5 165.5s5 33-5 37 20 21 20 21h17l11-23s-10-18-4-35-39 0-39 0z"
        fill="#ffb8b8"
      />
      <path
        d="M273.5 165.5s5 33-5 37 20 21 20 21h17l11-23s-10-18-4-35-39 0-39 0z"
        opacity={0.1}
      />
      <path
        d="M266.5 193.5s11 12 13 14 11 13 30-3 21 3 21 3v120s-16-7-29 0-40-2-40-2l-5-124z"
        fill="#d0cde1"
      />
      <path
        d="M273.845 193.5s-9.345-4-17.345 0-36 24-34 33 28 72 10 102-21 38-21 38l44-23s34-21 31-34-12.655-116-12.655-116zM313.512 193.5s20.988 2 21.988 4S367 208 365 217s-29.5 89.5-17.5 104.5 32 36 25 38-19-3-31 0-40-22-37-49 .024-110 9.012-117z"
        fill="#3f3d56"
      />
      <path
        d="M263.5 384.5v10s-5 23 11 22 6-28 6-28l-1-9zM308.5 385.5s-11 23 0 25 19-13 19-17-19-8-19-8z"
        fill="#ffb8b8"
      />
      <path fill="#2f2e41" d="M272.5 650.524l-2-.048 6-230 2 .048-6 230z" />
      <ellipse cx={323} cy={152} rx={2} ry={4} fill="#ffb8b8" />
      <ellipse cx={264} cy={152} rx={2} ry={4} fill="#ffb8b8" />
      <path
        d="M340.5 206.5s25 0 24 10-34 182-34 182-14-13-26-10zM243.5 209.5s-25 5-25 20 36 159 41 159 24-3 24-8-40-171-40-171z"
        fill="#3f3d56"
      />
      <path
        d="M249 239l22.5 87.991S261 250 249 239zM336.517 226.636L313.761 341.68s7.273-108.408 22.756-115.044z"
        opacity={0.2}
      />
      <circle cx={293.5} cy={152.5} r={29} fill="#ffb8b8" />
      <path
        fill="#2f2e41"
        d="M320.079 122.998l-21.676-11.354-29.933 4.645-6.193 27.353 15.416-.593L282 133v9.884l7.114-.274 4.128-15.999 2.581 17.031 25.289-.516-1.033-20.128zM10 650h546v2H10z"
      />
      <path
        d="M464.583 650.02l-2-.04a463.835 463.835 0 017.1-66.287c8.648-46.88 23.029-77.67 42.743-91.511l1.148 1.636c-46.052 32.334-48.968 154.966-48.991 156.202zM489.583 649.541l-2-.04c.043-2.214 1.293-54.413 21.843-68.84l1.148 1.636c-19.72 13.846-20.981 66.712-20.991 67.244z"
        fill="#2f2e41"
      />
      <circle cx={525} cy={483} r={10} fill={props?.tertiary || '#6c63ff'} />
      <circle cx={520} cy={570} r={10} fill={props?.tertiary || '#6c63ff'} />
      <path
        d="M484.922 499.995c1.88 12.004-3.02 22.74-3.02 22.74s-7.944-8.726-9.824-20.73 3.02-22.74 3.02-22.74 7.944 8.726 9.824 20.73zM517.609 529.148c-11.493 3.943-22.919.99-22.919.99s7.208-9.344 18.701-13.286 22.919-.99 22.919-.99-7.208 9.344-18.701 13.286zM517.978 605.31a31.134 31.134 0 01-16.064.693 28.374 28.374 0 0129.172-10.006 31.134 31.134 0 01-13.108 9.313zM2 192H0V62h128v2H2v128zM583.904 192h-2V64h-126v-2h128v130zM2 302H0v130h128v-2H2V302zM583.904 302h-2v128h-126v2h128V302z"
        fill="#2f2e41"
      />
      <path fill="#3f3d56" d="M36 123h140v2H36z" />
      <path
        fill="#3f3d56"
        d="M74 105h2v18.5h-2zM135 105h2v18.5h-2zM414 264h140v2H414z"
      />
      <path
        fill="#3f3d56"
        d="M452 246h2v18.5h-2zM513 246h2v18.5h-2zM45 358h140v2H45z"
      />
      <path fill="#3f3d56" d="M83 340h2v18.5h-2zM144 340h2v18.5h-2z" />
    </svg>
  );
}
