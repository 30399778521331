import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function UpdatedResume({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={888}
      height={567.337}
      viewBox="0 0 888 567.337"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{'updated_resume'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path fill="#e6e6e6" d="M97.182 0h420v550h-420z" />
      <path
        fill="#fff"
        d="M122.182 28.5h370v100h-370zM261.182 162.5h230v360h-230z"
      />
      <path
        fill="#e6e6e6"
        d="M279.34 65.866h93.423v7.022H279.34zM266.84 84.112h117.573v7.022H266.84zM325.34 245.575h93.423v7.022H325.34z"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M325.34 266.189h144.266v7.022H325.34z"
      />
      <path
        fill="#e6e6e6"
        d="M326.84 285.821h117.573v7.022H326.84zM325.34 307.362h68.637v7.022H325.34z"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M325.34 328.403h103.592v7.022H325.34z"
      />
      <path fill="#e6e6e6" d="M282.759 243.366h11.44v11.44h-11.44z" />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M282.759 263.98h11.44v11.44h-11.44z"
      />
      <path
        fill="#e6e6e6"
        d="M282.759 283.612h11.44v11.44h-11.44zM282.759 305.153h11.44v11.44h-11.44z"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M282.759 326.194h11.44v11.44h-11.44z"
      />
      <path fill="#e6e6e6" d="M325.34 349.575h93.423v7.022H325.34z" />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M325.34 370.189h144.266v7.022H325.34z"
      />
      <path
        fill="#e6e6e6"
        d="M326.84 389.821h117.573v7.022H326.84zM325.34 411.362h68.637v7.022H325.34zM325.34 432.403h103.592v7.022H325.34zM282.759 347.366h11.44v11.44h-11.44z"
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M282.759 367.98h11.44v11.44h-11.44z"
      />
      <path
        fill="#e6e6e6"
        d="M282.759 387.612h11.44v11.44h-11.44zM282.759 409.153h11.44v11.44h-11.44zM282.759 430.194h11.44v11.44h-11.44z"
      />
      <path
        d="M183.065 48.771a29.729 29.729 0 1029.73 29.729 29.816 29.816 0 00-29.73-29.729zm0 8.919a8.919 8.919 0 11-8.918 8.918 8.949 8.949 0 018.918-8.918zm0 42.935a21.657 21.657 0 01-17.837-9.519c.143-5.946 11.892-9.219 17.837-9.219s17.695 3.273 17.838 9.22a21.693 21.693 0 01-17.838 9.518z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path fill="#3f3d56" d="M0 548.802h888v2.241H0z" />
      <path
        d="M716.929 168.845s10.15 21.428 16.917 23.684l-19.173 29.322-15.789-19.172s1.128-13.534-2.255-19.173 20.3-14.661 20.3-14.661z"
        fill="#fbbebe"
      />
      <circle cx={701.14} cy={166.589} r={21.428} fill="#fbbebe" />
      <path
        fill="#fbbebe"
        d="M671.817 319.97l5.639 15.789 6.767-7.895-5.679-17.984-6.727 10.09z"
      />
      <path
        d="M687.606 312.075s-6.766 28.195-3.383 41.728 13.533 106.013 13.533 106.013l6.767 73.307 27.067 2.256-3.383-85.713 3.383-74.434 19.173 72.179v87.968l32.706 1.127-3.383-113.907s2.255-87.968-12.406-99.246-16.917-13.534-16.917-13.534z"
        fill="#2f2e41"
      />
      <path
        d="M710.162 531.995l-2.255 7.895-3.384 10.15s-10.15 20.3 6.767 16.917 14.661-11.278 14.661-11.278 5.64-3.383 4.512-4.511-1.128-18.045-1.128-18.045zM775.574 531.995l2.256 7.895 3.383 10.15s10.15 20.3-6.766 16.917-14.662-11.278-14.662-11.278-5.639-3.383-4.511-4.511 1.128-18.045 1.128-18.045z"
        fill="#2f2e41"
      />
      <path
        d="M714.673 208.318l13.794-20.3 25.68 13.533 3.383 117.29s-14.662-9.022-37.218 2.256-37.217 3.384-37.217 3.384l13.534-50.751-5.64-67.668 8.066-7.32z"
        fill="#e6e6e6"
      />
      <path
        d="M699.058 196.607l-4.685 3.816s-29.323 14.662-30.45 24.812l20.3 55.262s5.639 12.405 1.128 16.917-9.023 15.789-7.895 21.428-6.767 29.322 2.256 29.322 30.45-54.134 29.322-75.562-1.908-77.556-9.976-75.995z"
        fill="#2f2e41"
      />
      <path
        d="M674.073 218.468l-6.952.744s-6.582 4.895-6.582 16.173-1.128 45.112-1.128 45.112-2.255 16.917 4.512 29.322 7.894 15.79 7.894 15.79l11.278-13.534V283.88l4.511-18.045zM725.076 183.377l11.025 4.64s38.346 10.15 43.985 27.068l-15.79 39.472s11.278 72.18 7.895 72.18h-3.383s9.022 6.766 3.383 9.022-19.173 20.3-22.556 12.405-23.684-101.501-21.428-121.802-5.134-40.858-3.13-42.985zM709.598 138.958v-2.255h-5.639s-9.022-5.64-16.917 1.128-11.278 10.15-11.278 10.15l-3.383 4.51 3.383 1.129-3.383 3.383h3.383l-2.255 3.383 6.321 8.459s-.682-8.459 6.084-8.459 14.662-9.022 14.662-9.022l16.917 12.406s-1.128-6.735 2.82-6.751c0 0 1.691-16.933-10.715-18.06z"
        fill="#2f2e41"
      />
      <path
        d="M787.98 319.97l-2.255 23.683s0 34.962-12.406 31.579-2.256-32.706-2.256-32.706l1.714-21.21z"
        fill="#fbbebe"
      />
      <path
        d="M763.169 209.446s14.66-5.64 19.172 11.278 10.15 50.75 10.15 50.75a50.751 50.751 0 010 37.218l-3.383 16.917-19.173-2.256-4.51-38.345-4.512-48.495z"
        fill="#2f2e41"
      />
    </svg>
  );
}
