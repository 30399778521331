import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Teaching({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={991.14}
      height={770.178}
      viewBox="0 0 991.14 770.178"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{'professor'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path
        d="M289.58 0L131.729 85.502a40.156 40.156 0 11-70.587 38.234L7.317 152.89l139.42 257.394L429 257.394z"
        fill="#f2f2f2"
      />
      <circle
        cx={94.604}
        cy={105.668}
        r={32.587}
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M685.955 689.064l170.672-55.671a40.156 40.156 0 1176.319-24.894l58.194-18.983-90.777-278.296-305.185 99.547z"
        fill="#f2f2f2"
      />
      <circle
        cx={896.776}
        cy={620.244}
        r={32.587}
        fill={props?.tertiary || '#6c63ff'}
      />
      <path fill="#3f3d56" d="M115.5 152.678h788v358h-788z" />
      <path fill="#fff" d="M128.5 165.678h762v325h-762z" />
      <path fill="#3f3d56" d="M739 475.178h74v25h-74z" />
      <path fill={props?.tertiary || '#6c63ff'} d="M681 494.178h54v7h-54z" />
      <path
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M190.5 288.678h291l45.116 21.979 71.884 35.021 47-88h133"
      />
      <path
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M304.925 288.678l21.575 51h53M385.807 288.678l73.693-40h52"
      />
      <circle
        cx={304.5}
        cy={288.678}
        r={9}
        fill={props?.tertiary || '#6c63ff'}
      />
      <circle
        cx={388.5}
        cy={288.678}
        r={9}
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M526.5 310.678l6 89h52M626.65 292.971l-57.15-44.293h54M721.051 257.678l55.449 44h52"
      />
      <circle
        cx={526.5}
        cy={310.678}
        r={9}
        fill={props?.tertiary || '#6c63ff'}
      />
      <circle
        cx={720.5}
        cy={257.678}
        r={9}
        fill={props?.tertiary || '#6c63ff'}
      />
      <circle
        cx={626.5}
        cy={292.678}
        r={9}
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M327.5 347.678h52M511.5 257.678h0zM623.5 256.678h0zM623.5 264.678h0zM828.5 309.678h0zM809.5 317.678h0zM584.5 407.678h0z"
      />
      <circle
        cx={809.542}
        cy={75.53}
        r={14.756}
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <ellipse cx={152} cy={742.178} rx={152} ry={28} fill="#f2f2f2" />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M256.389 493.658l-27.117 13.681 1.755 3.495 27.116-13.681-1.754-3.495z"
      />
      <path
        d="M251.98 468.211l-1.218 7.31s1.827 50.556-11.573 34.72-4.264-37.157-4.264-37.157l3.655-6.09zM174.013 234.31s-2.436 25.583-3.654 26.802 18.273 24.364 18.273 24.364l17.055-15.837s-1.218-23.146 2.437-28.02-34.11-7.309-34.11-7.309z"
        fill="#fbbebe"
      />
      <path
        d="M174.013 234.31s-2.436 25.583-3.654 26.802 18.273 24.364 18.273 24.364l17.055-15.837s-1.218-23.146 2.437-28.02-34.11-7.309-34.11-7.309z"
        opacity={0.1}
      />
      <path
        fill="#3f3d56"
        d="M128.939 700.894v21.928l-8.528 1.219-12.182-3.655 3.654-23.147 17.056 3.655z"
      />
      <path
        d="M117.974 711.858s-3.654-9.746-9.745-8.527-7.31 8.527-7.31 8.527-4.873 18.274-12.182 24.365-14.619 21.928 0 24.365 26.801-3.655 26.801-4.873 3.655-8.528 9.746-9.746 10.964-2.437 10.964-7.31-3.655-9.746-3.655-9.746-1.218-23.108-3.654-20.081a24.234 24.234 0 00-3.655 7.9z"
        fill="#2f2e41"
      />
      <path
        fill="#3f3d56"
        d="M181.323 689.93l-4.873 18.273 12.182 2.437 6.091-6.091 1.218-15.837-14.618 1.218z"
      />
      <path
        d="M180.104 705.767s4.873-3.655 7.31-3.655h3.654s0-8.527 6.092-8.527 4.873 6.09 4.873 6.09 13.4 20.71 17.055 24.366 23.146 14.618 7.31 19.491-26.802 4.873-34.111-3.654c0 0-7.31-3.655-12.183-3.655s-10.964-2.437-10.964-6.091 3.655-10.964 3.655-10.964 2.436-21.929 3.655-19.492 3.654 6.091 3.654 6.091z"
        fill="#2f2e41"
      />
      <path
        d="M127.72 409.736v40.202s-13.4 77.967-13.4 103.55-20.71 147.406-10.964 149.843 32.892 6.09 34.11 2.436 9.746-119.387 10.964-120.605 26.801-97.459 26.801-97.459l4.873 1.218 2.437 109.641s-12.182 98.677-6.091 98.677 31.674-1.218 31.674-8.527 13.4-142.534 13.4-142.534 15.837-107.204 9.746-118.168-6.09-17.056-6.09-17.056z"
        fill="#2f2e41"
      />
      <circle cx={194.114} cy={228.828} r={24.365} fill="#fbbebe" />
      <path
        d="M188.632 270.857s-15.228-12.791-16.446-17.664c0 0-7.919 7.919-7.919 9.137s-34.11 4.873-42.638 14.619-15.837 30.455-15.837 30.455l20.71 38.984s1.218 21.928 1.218 24.365-6.09 41.42-3.654 42.638 26.8 13.4 47.51 10.964 41.42-6.091 47.512-6.091 10.964 2.436 10.964 0-3.655-7.31-3.655-9.746-1.218-8.528-1.218-12.182-2.436-9.746-2.436-14.62 26.8-70.657 26.8-70.657-1.217-20.71-20.71-30.456-21.927-13.4-21.927-13.4-.38-7.31-1.409-7.31-4.683 10.964-14.428 12.183z"
        fill="#d0cde1"
      />
      <path
        fill="#fbbebe"
        d="M122.847 432.883l8.528 13.4 21.928-4.873-15.837-18.273-14.619 9.746z"
      />
      <path
        d="M242.234 301.313l7.31 9.746s14.619 70.658 12.182 96.24-4.873 45.075-4.873 45.075 4.873 18.274 1.218 18.274-25.583 1.218-25.583-2.437 2.437-26.8 2.437-26.8l-6.091-53.603-13.4-18.274z"
        fill="#d0cde1"
      />
      <path
        d="M177.565 217c1.494-.633 3.22-.12 4.82-.39 1.878-.317 3.559-1.709 5.455-1.533a10.484 10.484 0 012.598.843 3.39 3.39 0 002.66.096 1.512 1.512 0 00.58-2.271 7.52 7.52 0 004.326-1.863 3.23 3.23 0 01.826-.62 2.696 2.696 0 012.123.33l2.787 1.286a3.526 3.526 0 011.593 1.14c.332.516.397 1.212.866 1.607.6.505 1.495.258 2.278.308a2.912 2.912 0 012.63 2.84c-.012.818-.31 1.821.338 2.322.375.29.908.244 1.347.422 1.14.463 1.144 2.05.976 3.267s-.085 2.826 1.086 3.2c.825.265 1.668-.298 2.524-.424 1.293-.19 2.489.62 3.64 1.238s2.679 1.036 3.676.192c.928-.785.868-2.222.613-3.41a18.79 18.79 0 00-1.787-4.887 3.102 3.102 0 01-.519-1.52 2.754 2.754 0 011.278-1.861c1.41-1.114 3.032-2.237 3.488-3.975.335-1.275-.042-2.62-.012-3.94.035-1.604.673-3.202.374-4.778-.543-2.859-3.723-4.234-5.777-6.295-2.821-2.832-3.737-7.361-7.067-9.573-2.664-1.77-6.149-1.558-9.182-2.57-3.333-1.112-6.015-3.667-9.302-4.907a12.963 12.963 0 00-11.019.967 11.028 11.028 0 01-3.24 1.655 11.647 11.647 0 01-2.948-.035 11.381 11.381 0 00-5.624 1.16 3.144 3.144 0 00-1.575 1.382c-.588 1.307.267 3.35-1.024 3.976-.601.291-1.311.012-1.98.027-1.299.028-2.298 1.159-2.916 2.302a11.033 11.033 0 01-2.045 3.29c-1.588 1.39-4.473 1.62-4.888 3.69a20.124 20.124 0 00.149 2.623c-.12 1.835-2.084 3.229-1.981 5.064.118 2.118 2.808 3.152 3.527 5.147.548 1.519-.173 3.161-.669 4.698s-.645 3.506.636 4.49c.502.386 1.147.544 1.66.916 1.324.964 1.307 2.908 1.16 4.54a2.135 2.135 0 00.482 1.944 1.799 1.799 0 001.472.177 6.01 6.01 0 003.804-2.863c.426-.705.512-1.327 1.287-1.701.526-.254 1.165-.027 1.7-.357 2.547-1.568 1.67-6.042 4.796-7.366z"
        fill="#2f2e41"
      />
      <path
        d="M109.447 303.75l-3.655 3.654s-21.928 65.785-14.619 80.404 21.929 51.166 26.801 49.947 21.929-12.182 21.929-13.4-20.71-35.329-20.71-35.329-2.437-7.31-1.219-8.528 1.219 0 0-2.436-1.218 0 1.219-2.436 2.436-2.437 2.436-3.655 7.31-31.674 7.31-31.674z"
        fill="#d0cde1"
      />
      <path
        fill="#2f2e41"
        d="M128.329 446.892l26.802-8.527 3.654 8.527-29.237 6.091-1.219-6.091z"
      />
      <path
        opacity={0.1}
        d="M128.329 446.892l26.802-8.527 3.654 8.527-29.237 6.091-1.219-6.091zM164.876 262.939l20.71 17.055 2.437-3.655-23.147-13.4zM206.296 267.812l-14.618 8.527 1.218 3.655 13.4-12.182z"
      />
    </svg>
  );
}
