import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';

import bp from './config/default-blueprint.json';

export const provVerifierDS = new DataSet({
    fieldId:"_id"
});

export const provVerifierTransformedDS = new DataSet({
    fieldId: "_id"
});

provVerifierTransformedDS.update(bp?.verifierDetails);

export const provVerifier = () => {
    useEffect(() => {
        return function cleanup() {
          provVerifierDS.clear();
          provVerifierTransformedDS.clear();
        };
      }, []);

      const _getVerifierDetails = () => {
        let _data = provVerifierTransformedDS.get(bp?.verifierDetails?._id);
        let _verifierDetails;
    
        if(_data){
            _verifierDetails = _data;
        }
        return _verifierDetails;
    }
    
    const verifierDetails = _getVerifierDetails();

    return{
    verifierDetails
    }
}

