import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { PageNotFoundLG } from './PageNotFound.lg';
import { PageNotFoundSM } from './PageNotFound.sm';

export interface IProps {
}

export const PageNotFound = (props: IProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <PageNotFoundSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <div />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <PageNotFoundLG {...props} />;
};
