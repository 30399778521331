import React, { useContext } from 'react';
import { ProfileTemplateV } from 'react-profile-template-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { ViewportProvider, useViewport } from 'react-viewport-provider'
import { Text, View, StyleSheet } from 'react-native';
import Icon from 'rsuite/lib/Icon';
import { Avatar } from 'react-native-elements';
import { ThemeContext } from '../../../providers/theme'
import { IProps } from './index';
import { FooterDetail } from './Footer';
import { useHistory } from 'react-router';
import { Header } from './Header';
import { ImageBackgroundV } from 'react-image-background';
import { CERT_BG_IMAGE } from '../../../services/loadAssets';
import {VerifyCardV} from '../../../widgets/VerifierCardV';
import config from '../config/default-blueprint.json';

const BP = config?.verifierDetails;

export const VerifierLG = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const history = useHistory();
    const { width } = useViewport();
    const styles = {
        contactItemStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        footerColumnStyle: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            textAlign: 'left',
            margin: '0 auto'
        },
        footerHeaderStyle: {
            paddingLeft: '5%',
            paddingBottom: 15,
            color: '#fff',
            fontSize: 20,
            fontWeight: 600,
            display: 'block'
        },
        footerTextStyle: {
            paddingLeft: '5%',
            textAling: 'center',
            fontSize: 18,
            color: '#fff',
        },
        footerContainerStyle: {
            backgroundColor: '#25242A',
            padding: '1% 5%'
        },
        headerStyle: {
            backgroundColor: theme?.palette?.primary,
            width: width * 0.99,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: '5%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        hederLogoStyle: {
            marginLeft: '5%'
        },
        bgStyle: {
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            alignItems:'center'
        }
    }

    const onFooterNavSelect = (eventKey: string) => {
        if (eventKey === 'terms') {
            history.push('/termsandcondition');
            window.scrollTo(0, 0);
        }
        if (eventKey === 'privacy') {
            history.push('/privacy');
            window.scrollTo(0, 0)
        }
        if (eventKey === 'aboutus') {
            history.push('/')
        }
    }

    const _cred = props.verifierDetails?.credentialDetails?.map((item: any) => (
        {
            label: item?.title,
            value: item?.subTitle,
            link: item?.link
        }
    ));

    let productData ={
        image: props.verifierDetails?.avatar ?? `https://i.ibb.co/PDbsbqK/klefki-cred-logo.png`,
        title: props.verifierDetails?.name,
        subTitle: props.verifierDetails?.docNumber,
        link: props.verifierDetails?.docLink,
    }

    return (
        <>
            <Header
                headerLogo={BP?.headerLogo}

            />
                <View style={{alignItems:'center', justifyContent:'center', alignSelf:'center', height:'80vh', width:'40%'}}> 
                <VerifyCardV                    
                    productData={productData}
                    verifyData={_cred}
                    appearance={'web'}
                />
                </View>

            {/* <FBGridV justify={'start'} style={StyleSheet.flatten([styles.footerContainerStyle])}>
                {_getFooterContent()}
            </FBGridV> */}
            <FooterDetail onFooterNavSelect={onFooterNavSelect} />
        </>
    );
}