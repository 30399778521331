// @ts-nocheck
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { LandingScreen } from '../screens/LandingPage';
import { TermsandConditionScreen } from '../screens/TermsAndCondition';
import { PrivacyScreen } from '../screens/PrivacyPolicy';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import {NotFoundScreen} from '../screens/PageNotFound';
import {VerifierScreen} from '../screens/VerifierView';

import auth from '../services/authService';

import { useAuth } from '../services/auth-service/auth.provider';

const { isAuthenticated } = useAuth();

const routes = [
  {
    path: '/credential',
    component: VerifierScreen
  },
  {
    path: '/hello',
    component: NotFoundScreen
  },
  {
    path: '/termsandcondition',
    component: TermsandConditionScreen
  },
  {
    path: '/privacy',
    component: PrivacyScreen
  },
  {
    path: '/',
    component: LandingScreen,
  },
];

// const fakeAuth = {
//   isAuthenticated: false,
//   authenticate(cb) {
//     fakeAuth.isAuthenticated = true;
//     setTimeout(cb, 100); // fake async
//   },
//   signout(cb) {
//     fakeAuth.isAuthenticated = false;
//     setTimeout(cb, 100);
//   },
// };

const RouterConfig = props => (
  <Switch>
    {/* <PrivateRoute path="/verify" component={VerifyUserScreen} /> */}
    {/* <Route path="/credential" component={ViewCertificateScreen} /> */}
    <Route path="/hello" component={NotFoundScreen} />
    <Route path="/termsandcondition" component={TermsandConditionScreen} />
    <Route path="/privacy" component={PrivacyScreen} />
    <Route path="/credential" component={VerifierScreen} />
    <Route path="/" component={LandingScreen} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={'none'}>
        <Stack.Screen name="Dashboard" component={DashboardScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <RouterConfig />
    </Router>
  );
};
