import DotIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {useViewport} from 'react-viewport-provider';
import {IProps} from './index';
import './style.css';

export const BodyContentSM = (props: IProps) => {
  const {height, width, bp} = useViewport();
  const styles = {
    headerStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 15,
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    videoSectionStyle: {
      display: 'flex',
      paddingLeft: '5%',
      paddingRight: '5%',
      justifyContent: 'center',
      textAlign: 'center',
      paddingBottom: 80,
      paddingTop: 80,
    },
    vTitleStyle: {
      display: 'block',
      fontSize: 36,
      color: '#fff',
      marginBottom: 25,
      lineHeight: 30,
    },
    vDescriptionStyle: {
      display: 'block',
      fontSize: 16,
      paddingBottom: '3%',
      color: '#fff',
    },
    sectionOddStyle: {
      justifyContent: 'space-around',
      alignItems: 'center',
      fontSize: 14,
      lineHeight: bp !== 'xs' && bp !== 'sm' ? '24px' : '18px',
      letterSpacing: 2,
      paddingLeft: 5,
      paddingRight: 5,
    },
    sectionEvenStyle: {
      paddingLeft: '5%',
      paddingRight: '5%',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 80,
    },
    sectionTitleStyle: {
      fontSize: 18,
      marginBottom: 25,
    },
    textContainerStyle: {
      backgroundColor: '#F5F7FA',
      border: '1px solid #327098',
      borderRadius: 10,
      padding: 20,
      opacity: 0.9,
    },
    parallaxStyle: {
      background: `url(${props?.mobileParallaxBg})`,
      backgroundAttachment: 'scroll',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  };
  const _renderContent = () => {
    let _timelineElements =
      Array.isArray(props?.bodyContent) &&
      props?.bodyContent.map(item => {
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{background: '#F5F7FA', color: '#000', opacity: 0.9}}
            contentArrowStyle={{borderRight: '7px solid  #F5F7FA'}}
            iconStyle={{background: item?.dotColor, color: '#fff'}}
            icon={<DotIcon />}
          >
            <Text style={StyleSheet.flatten([styles.sectionTitleStyle])}>
              {item?.title}
            </Text>
            <ul>
              {item?.list?.map(ele => {
                return (
                  <li style={{marginBottom: 8, fontSize: 17}}>{ele?.li}</li>
                );
              })}
            </ul>
          </VerticalTimelineElement>
        );
      });

    return _timelineElements;
  };

  return (
    <>
      <View style={StyleSheet.flatten([styles.parallaxStyle])}>
        <VerticalTimeline className="vertical-timeline-custom-line">
          {_renderContent()}
        </VerticalTimeline>
      </View>
    </>
  );
};
