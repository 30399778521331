import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { LandingPageLG } from './LandingPage.lg';
import { LandingPageSM } from './LandingPage.sm';

export interface IProps {
}

export const LandingPage = (props: IProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <LandingPageSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <div />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <LandingPageLG {...props} />;
};
