import * as React from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Graduation({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={795.394}
      height={574.038}
      viewBox="0 0 795.394 574.038"
      aria-labelledby={titleId}
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__a"
          x1={-450.79}
          y1={2803.047}
          x2={-450.79}
          y2={2729.348}
          gradientTransform="rotate(90 1103.938 2113.595)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.535} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
      </defs>
      {title === undefined ? (
        <title id={titleId}>{'Graduation'}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <ellipse
        cx={232.058}
        cy={450.069}
        rx={35.095}
        ry={5.264}
        transform="rotate(-63.611 -.497 531.685)"
        fill="#e0e0e0"
      />
      <ellipse
        cx={670.577}
        cy={667.856}
        rx={35.095}
        ry={3.577}
        transform="rotate(-63.611 438.023 749.472)"
        fill="#e0e0e0"
      />
      <path
        fill="#f55f44"
        d="M220.406 405.284l16.237 13.407-44.691 54.123-16.237-13.407z"
      />
      <path
        opacity={0.05}
        d="M222.368 482.555l-23.063-5.423 16.065-68.327 23.063 5.423z"
      />
      <path
        fill="#f55f44"
        d="M222.367 482.554l-20.498-4.82 16.065-68.326 20.498 4.82z"
      />
      <path
        transform="rotate(-63.611 218.781 640.484)"
        fill="url(#prefix__a)"
        d="M414.486 314.082h73.699v489.572h-73.699z"
      />
      <path
        d="M452.713 536.124c-8.584-4.118-444.844-220.718-438.557-217.598 24.214-36.958 31.197-62.876 31.197-62.876L483.91 473.25s-15.402 27.029-31.197 62.875z"
        fill="#f5f5f5"
      />
      <path
        opacity={0.05}
        d="M237.123 350.8l36.154 17.94-31.197 62.875-36.154-17.938z"
      />
      <path
        fill="#f55f44"
        d="M238.695 351.58l33.01 16.38-31.197 62.875-33.01-16.378z"
      />
      <path
        opacity={0.05}
        d="M243.41 353.92l22.007 10.92-31.197 62.875-22.007-10.919z"
      />
      <path
        fill="#f55f44"
        d="M244.983 354.7l18.862 9.36-31.197 62.875-18.862-9.359z"
      />
      <circle cx={261.306} cy={563.925} r={2.926} fill="#dbdbdb" />
      <g opacity={0.5} fill="#47e6b1">
        <path d="M29.394 482h3v17h-3z" />
        <path d="M39.394 489v3h-17v-3z" />
      </g>
      <g opacity={0.5} fill="#47e6b1">
        <path d="M785.394 270h3v17h-3z" />
        <path d="M795.394 277v3h-17v-3z" />
      </g>
      <g opacity={0.5} fill="#47e6b1">
        <path d="M47.394 59h3v17h-3z" />
        <path d="M57.394 66v3h-17v-3z" />
      </g>
      <g opacity={0.5} fill="#47e6b1">
        <path d="M695.394 33h3v17h-3z" />
        <path d="M705.394 40v3h-17v-3z" />
      </g>
      <path
        d="M12.29 253.456a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.767 1.767 0 00.408.08 1.843 1.843 0 001.221-3.31 1.767 1.767 0 00-.361-.205zM184.29 56.456a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.767 1.767 0 00.408.08 1.843 1.843 0 001.221-3.31 1.767 1.767 0 00-.361-.205zM394.29 567.456a3.675 3.675 0 01-2.048-4.44 1.766 1.766 0 00.08-.408 1.843 1.843 0 00-3.31-1.221 1.766 1.766 0 00-.204.361 3.675 3.675 0 01-4.441 2.048 1.766 1.766 0 00-.408-.08 1.843 1.843 0 00-1.221 3.31 1.766 1.766 0 00.362.204 3.675 3.675 0 012.047 4.441 1.766 1.766 0 00-.08.408 1.843 1.843 0 003.31 1.221 1.766 1.766 0 00.205-.362 3.675 3.675 0 014.44-2.047 1.767 1.767 0 00.408.08 1.843 1.843 0 001.221-3.31 1.767 1.767 0 00-.361-.205z"
        fill="#4d8af0"
        opacity={0.5}
      />
      <circle cx={759.394} cy={444} r={6} fill="#f55f44" opacity={0.5} />
      <circle cx={627.394} cy={482} r={6} fill="#4d8af0" opacity={0.5} />
      <circle cx={103.394} cy={541} r={6} fill="#47e6b1" opacity={0.5} />
      <circle cx={411.394} cy={6} r={6} fill="#f55f44" opacity={0.5} />
      <path
        d="M204.894 357.5l-24 36s8 37 185 36 191-36 191-36l-33-48-101-69z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <path
        d="M204.894 357.5l-24 36s8 37 185 36 191-36 191-36l-33-48-101-69z"
        opacity={0.15}
      />
      <path
        fill={props?.tertiary || '#6c63ff'}
        d="M715.394 224l-315 139-384-139 352-160 172.79 79.67 6.19 2.86L715.394 224z"
      />
      <path
        opacity={0.15}
        d="M715.394 224l-315 139-384-139 352-160 172.79 79.67 6.19 2.86L715.394 224z"
      />
      <path
        fill="#f5f5f5"
        d="M553.894 257.32l-6 .36-6.71-114.01 6.19 2.86 6.52 110.79z"
      />
      <path
        d="M208.894 223.5l-28 170s201-65 376 0l-26-180s-235-29-322 10z"
        fill={props?.tertiary || '#6c63ff'}
      />
      <ellipse cx={553.394} cy={272} rx={29} ry={20} fill="#f5f5f5" />
    </svg>
  );
}
