import React from 'react';

import {LandingPageV } from '../../../../widgets/DigicertLanding';

import config from './config/default-blueprint.json';
import {BookReading} from './illustrators/BookReading';
import {Books} from './illustrators/Books';
import {Certification} from './illustrators/Certification';
import {Exams} from './illustrators/Exams';
import {FeelingProud} from './illustrators/FeelingProud';
import {Graduation} from './illustrators/Graduation';
import {Hire} from './illustrators/Hire';
import {JobOffers} from './illustrators/JobOffers';
import {Learning} from './illustrators/Learning';
import {Professor} from './illustrators/Professor';
import {Teaching} from './illustrators/Teaching';
import {UpdatedResume} from './illustrators/UpdatedResume';
import { useHistory } from 'react-router-dom';

const benefits = {
    benefitsTitle: 'Blockchain based Decentralized ID & Credentials platform',
    benefitsDesciption:
      'Klefki is a blockchain based secure, frictionless platform that enables trust, inclusion and efficiency in the ecosystem of Public and Private enterprises',
    benefitsData: [
      {
        icon: 'https://i.ibb.co/0qSd1f4/blockchain.png',
        iconStyle: {
          tintColor: '#3B7696',
        },
        title: 'Own Identity',
        desciption:
          'Provide a platform for individuals to own and control their Identity.',
      },
      {
        icon: 'https://i.ibb.co/xF6ZZzM/document.png',
        iconStyle: {
          tintColor: '#3B7696',
        },
        title: 'Secure Store',
        desciption:
          'Accept credentials from Issuers and Store Securely in mobile wallet.',
      },
      {
        icon: 'https://i.ibb.co/9grtYys/verification.png',
        iconStyle: {
          tintColor: '#3B7696',
        },
        title: 'Secure Share',
        desciption:
          'Share credentials with Verifiers on demand basis with Biometric consent.',
      },
      {
        icon: 'https://i.ibb.co/m9TFqhk/recruitment.png',
        iconStyle: {
          tintColor: '#3B7696',
        },
        title: 'Easy Verify',
        desciption:
          'Verify Credentials of Individuals directly with Individuals',
      },
    ],
  };
  
  const alternateSections = [
    {
      key: '01',
      parallaxBg: `${require('./assets/digicert.gif')}`,
      parallax: true,
      title: 'Governments and Public sectors',
      dotColor: '#3B7696',
      list: [
        {
          li:
            'Digital Transformation for Governments',
        },
        {
          li: 'Klefki: Data Protection Laws',
        },
        {
          li: 'Klefki: Near real time verification',
        },
      ],
    },
    {
      key: '02',
      title: 'Banking & Financial Services Institutions(BFSI)',
      dotColor: '#3B7696',
      list: [
        {
          li:
            'Digital Transformation for BFSI',
        },
        {
          li:
            'Klefki: Handling IDs and Credentials',
        },
      ],
    },
    {
      key: '03',
      title: 'Employers & Background Verifiers',
      dotColor: '#3B7696',
      list: [
        {
          li:
            'Digital Transformation in Employee screening and Digital on-boarding',
        },
        {
          li:
            'Klefki: Background Verification',
        },
      ],
    },
    {
      key: '04',
      title: 'Digital Certificates',
      dotColor: '#3B7696',
      list: [
        {
          li:
            'Digital Certificates',
        },
        {
          li:
            'Klefki: Digital certificate management',
        },
      ],
    }
  ];
  
  const pricingTable = [
    {
      buttonText: 'Get Started',
      title: 'Bronze',
      priceText: '$100 /Month',
    },
    {
      buttonText: 'Get Started',
      title: 'Silver',
      priceText: '$300 /Month',
    },
    {
      buttonText: 'Contact Us',
      title: 'Gold',
      priceText: 'Contact Us',
    }
];
  
const pricingDetail = {
    tableHeader: [
      {
        key: 'featureCategory',
        label: 'Feature Category',
      },
      {
        key: 'feature',
        label: 'Feature',
      },
      {
        key: 'bronze',
        label: 'Bronze',
      },
      {
        key: 'silver',
        label: 'Silver',
      },
      {
        key: 'gold',
        label: 'Gold',
      },
      {
        key: 'example',
        label: 'Industry Example - BFSI',
      },
    ],
    dataList: [
      {
        title: 'Product Stories',
        subTitle: 'Tell the stories behind  your projects',
        icon1: 'check',
        icon2: 'check',
        icon3: 'check',
      },
      {
        title: 'Proof Points',
        subTitle: 'Prove statement(eg recyclable)',
        icon1: 'check',
        icon2: 'check',
        icon3: 'check',
      },
      {
        title: 'Sharing Tools',
        subTitle: 'Publish across all digital and physical channel',
        icon1: 'check',
        icon2: 'check',
        icon3: 'check',
      },
      {
        title: 'Network Map',
        subTitle: 'Open up your supplirs market',
        icon1: 'close',
        icon2: 'close',
        icon3: 'check',
      },
      {
        title: 'Product Journey',
        subTitle: 'Show the full journey',
        icon1: 'close',
        icon2: 'close',
        icon3: 'check',
      },
    ],
  };
  
  const footer = {
    contactDetails: {
      title: 'CONTACT US',
      details: [
        {
          key: 'phone',
          label: 'Phone',
          value: '+91 98449 81710',
        },
        {
          key: 'email',
          label: 'Email',
          value: 'info@vlinder.io',
        },
      ],
    },
    socialLinks: {
      title: 'CONNECT WITH US',
      details: [
        {
          key: 'twitter',
          icon: 'https://i.ibb.co/k693xvN/twitter.png',
          link: 'https://twitter.com/GoVlinder/',
        },
        {
          key: 'linkedIn',
          icon: 'https://i.ibb.co/8jdMD1q/linkedIn.png',
          link: 'https://www.linkedin.com/company/vlinder-io/',
        },
      ],
    },
    copyright: {
      logo: 'https://i.ibb.co/VNHWjpM/vlinder-circle.png',
      footerNav: [
        {
          eventKey: 'aboutus',
          label: 'About Us',
        },
        {
          eventKey: 'terms',
          label: 'Terms And Condition',
        },
        {
          eventKey: 'privacy',
          label: 'Privacy',
        },
      ],
      copyRightText: {
        company: 'vlinder Inc.',
        poweredBy: 'vlinder',
        poweredByURL: 'https://vlinder.io/',
      },
    },
  };
  
  const HeaderMenu = [
    {
      eventKey: 'product',
      label: 'Product',
    },
    {
      eventKey: 'benefits',
      label: 'Benefits',
    },
    {
      eventKey: 'contact',
      label: 'Contact'
    }
  ];

export const Landing = () => {
    const history = useHistory();
    const onFooterNavSelect = (eventKey:string) => {
      console.log('foote nav item', eventKey);
      if(eventKey === 'aboutus'){
        history.push('/hello');
      }
      if(eventKey === 'terms'){
        history.push('/termsandcondition');
      }
      if(eventKey === 'privacy'){
        history.push('/privacy');
      }
    };

    const handleIllustrator = ({
        key,
        width,
        height,
        stroke,
        main,
        highlight,
        secondary,
        tertiary,
        index,
      }) => {
        switch (key) {
          // case 'university':
          //   return <University width={width} height={height} stroke={stroke} main={main} highlight={highlight} secondary={secondary} tertiary={tertiary} />
          // case 'verifier':
          //   return <Verifier width={width} height={height} stroke={stroke} main={main} highlight={highlight} secondary={secondary} tertiary={tertiary} />
          // case 'holder':
          //   return <Reader width={width} height={height} stroke={stroke} main={main} highlight={highlight} secondary={secondary} tertiary={tertiary} />
          case 'teaching':
            return (
              <Teaching
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'learning':
            return (
              <Learning
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'books':
            return (
              <Books
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'books-reading':
            return (
              <BookReading
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'professor':
            return (
              <Professor
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'exams':
            return (
              <Exams
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'graduation':
            return (
              <Graduation
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'certification':
            return (
              <Certification
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'joboffers':
            return (
              <JobOffers
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'updatedresume':
            return (
              <UpdatedResume
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'hire':
            return (
              <Hire
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'feeling-proud':
            return (
              <FeelingProud
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          default:
            return null;
        }
      };
    return (
        <LandingPageV
        bgImg={'https://i.ibb.co/KbqMfjt/slider-bg.png'}
        bgImgHeight={'80vh'}
        loginButtonTitle={'Login'}
        headerMenu={HeaderMenu}
        title={'Klefki'}
        onLoginButtonPress={()=>{history.push('/login')}}
        leftAvatar={'https://i.ibb.co/Wv3c8P9/klefki-icon-logo.png'}
        videoLink={'https://youtu.be/4lEh9b0_L4Y'}
        videoButtonColor={'red'}
        videoTitle={'Own and Share Your Achievements'}
        videoDescription={
          'Receive and own your credentials, pre-verified on the blockchain.  Share and get them verified instantly'
        }
        benefits={benefits}
        pricingData={pricingTable}
        pricingTitle={'Choose the right plan'}
        pricingSubTitle={
          'Start your blockchain based ID and Credential Management Today !'
        }
        renderIllustrator={handleIllustrator}
        illustratorsGroup={config.Illustrator.illustratorGroup}
        mobileillustratorsGroup={config.Illustrator.mobileIllustratorGroup}
        bodyContent={alternateSections}
        contactDetails={footer?.contactDetails}
        socialLinks={footer?.socialLinks}
        copyright={footer?.copyright}
        // parallaxBg={`${require('./assets/digicert.gif')}`} 
        //mobileParallaxBg={`${require('./assets/mobile-bg.png')}`}
        pricingDetailData={pricingDetail}
        onFooterNavSelect={onFooterNavSelect}
      />
    )
}