import React from 'react';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { View, StyleSheet, Text } from 'react-native';
import {ImageV} from 'react-native-image-vfw';
import { IconV } from 'react-icon-rs';
import Icon from 'rsuite/lib/Icon';
import BP from '../../config/default-blueprint.json';

export const LandingPageLG = () => {
    const styles = {
        bodyContainer: {
            // height: '100vh',
            // width: '100%',
            backgroundImage: 'linear-gradient(#2a4564, #141e30)',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            flexDirection: 'column',
            height: '100vh',
            width: '100%'
        },
        spinnerStyle: {
            color: '#fff',
            // position: 'absolute',
            // top: '46%',
            // left: '48%'
            alignSelf: 'center',
            marginTop: 15,
        },
        imgStyle: {
            // position: 'absolute',
            // top: '36%',
            // left: '44%'
            alignSelf: 'center'
        },
        titleStyle: {
            color: '#fff',
            marginTop: 15,
        }
    }
    // return (
    //     <FBGridV justify={'center'} align={'middle'}>
    //         <FBItemV colspan={24} style={StyleSheet.flatten([styles.bodyStyle])}>
    //             <img src={"https://i.ibb.co/cvXZ6ZG/Klefki-logo.png"} width={126} height={'auto'} style={styles.imgStyle} />
    //             <Icon icon={'spinner'} spin={true} size={'2x'} style={styles.spinnerStyle}>

    //             </Icon>
    //             <Text style={StyleSheet.flatten([styles.titleStyle])}>{BP?.blockchainVerifyingText}</Text>
    //         </FBItemV>
    //     </FBGridV>
    // )
    return (
        <View style={StyleSheet.flatten([styles.bodyContainer])}>
            <ImageV
                imageStyle={{width:117, height:40, resizeMode:'contain', alignSelf:'center'}}
                source={{uri:'https://i.ibb.co/qp0V52b/Klefki-logo-new.png'}}        
            />
            <Icon icon={'spinner'} spin={true} size={'2x'} style={styles.spinnerStyle} />
            <Text style={StyleSheet.flatten([styles.titleStyle])}>{BP?.verifierDetails?.blockchainVerifyingText}</Text>
        </View>
    )
}

